.dashboard-mobile-v2 {
    padding: 10px;
    .filter-header-mobile-v2 {
        .header-schedules {
            flex-direction: column;
            height: inherit !important;
        }
        .filter-left {
            width: 100%;
            .date-cpn-filter {
                width: 100%;
                .wrap-custom-date {
                    flex: 1;
                    .react-datepicker-wrapper {
                        text-align: center;
                    }
                }
            }
        }
        .filter-right {
            margin-top: 10px;
            justify-content: flex-end;
            width: 100%;
        }
    }
    .main-schedule-mobile {
        margin-top: 10px;
        // .e-scroll-container {
        //     .e-content-wrap {
        //         height: calc(100%+2px);
        //     }
        // }
        .e-left-indent {
            width: 65px !important;
        }
        .content-header::after {
            content: "";
            color: #006096 !important;
            font-weight: 600;
            font-size: 11px;
        }
        .e-time-cells-wrap {
            width: 65px !important;
            // height: 807px !important;
        }
        .template-wrap-lmt {
            > div {
                opacity: 1 !important;
            }
        }
    }
}
