.idbeauty-dashboard-mobile {
    overflow: hidden;
    .dashboard-v2 {
        .header-schedules {
            height: auto;
        }
        .filter-date {
            align-items: flex-start;
            flex-direction: column;
            display: flex;
            .filter-left {
                width: 100%;
                margin-bottom: 10px;
            }
            .date-cpn-filter {
                flex: 1;
                .wrap-custom-date {
                    flex: 1;
                }
            }
            .filter-right {
                width: 100%;
                .filter-branch {
                    flex: 1;
                    display: flex;
                    box-shadow: none !important;
                    margin-left: 35px;
                    margin-right: 0px;
                    .body-wrap {
                        flex: 1;
                        box-shadow: 0px 0px 10px #c1e4ff;
                        margin-right: 6px;
                    }
                    .statistic-of-day {
                        margin-right: 6px;
                        background: #fff;
                        border-radius: 3px;
                    }
                }
            }
        }
    }
    .wrap-dashboard-v2 {
        .content-header-wrap {
            .content-schedules {
                height: 40px !important;
            }
        }
        .content-schedules {
            height: calc(100vh - 230px) !important;
            .right-content-schedules-booking {
                height: calc(100vh - 230px) !important;
            }
        }
        @media (min-width: 768px) and (orientation: landscape) {
        }
    }
    .date-cpn-filter {
        min-width: 180px;
    }
}
.new-booking-drawer-cpn {
    .date-cpn-filter {
        min-width: 180px !important;
    }
}
