.pos-actions-detail {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .today {
        width: 72px;
        height: 100%;
        margin-right: 8px;

        .today-date {
            box-shadow: 0px 0px 10px #c1e4ff;
            border-radius: 3px;
            border: 0 !important;
            width: 100%;
        }
        .btn-date-filer {
            border-radius: 3px;
            min-width: 34px;
            text-align: center;
            color: #333;
            background: #fff;
            cursor: pointer;
            height: 100% !important;
            margin-top: 0px;
            &:hover {
                color: var(--main-color-blue-light);
                border-color: var(--main-color-blue-light);
            }
            &:focus {
                outline: unset !important;
            }
        }
    }
    .filter {
        display: flex;
        padding: 15px 20px;
        line-height: 32px;
        flex-direction: column;
        justify-content: center;
        .filter-date {
            display: flex;
            flex: 1;
        }
        .filter-action {
            display: flex;
            margin-top: 10px;
        }
        .date {
            width: 55px;
            font-size: 14px;
            color: #272829;
            margin-bottom: 2px;
        }
        .date-range {
            box-shadow: 0 0 10px #c1e4ff;
            border-radius: 3px;
            background: #fff;
            flex: 1;
            .body-wrap {
                border: 0 !important;
            }
        }
        .tab-filter {
            line-height: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            .ant-tag-checkable {
                height: 100%;
                display: flex;
                align-items: center;
                box-shadow: 0 0 10px #c1e4ff;
                min-width: 50px;
                justify-content: center;
                text-transform: uppercase;
            }
            .ant-tag-checkable-checked {
                background-color: #026eaa;
            }
            .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
                color: #026eaa;
            }
            .ant-tag-checkable:active {
                background-color: #026eaa;
            }
        }
    }
    .list-actions {
        flex: 1;
        height: calc(100% - 310px);
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        .header {
        }

        .actions {
            border-top: 1px dashed #a8aeb3 !important;
            overflow: auto;
            flex: 1;
            > div:first-child {
                border-top: 0px !important;
            }
            .item-action:nth-child(odd) {
                background: #fff;
            }
            .item-action {
                display: flex;
                // padding: 10px 0px;
                // border-top: 1px dashed #a8aeb3;
                padding: 10px 20px;
                background: #ecf8ff;
                .icon {
                    margin-right: 20px;
                    width: 50px;
                    height: 50px;
                    border: 1px solid #ccc;
                    border-radius: 50%;
                    display: flex;
                    margin-top: 5px;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    .img-pos {
                        height: 32px;
                    }
                    .img-gift {
                        height: 30px;
                    }
                    .icon-return {
                        transform: rotate(404deg);
                    }
                    .icon-receive {
                        transform: rotate(44deg);
                    }
                    .icon-topped {
                    }
                    .pos-status-icon {
                        position: absolute;
                        right: -3px;
                        bottom: -3px;
                        .anticon {
                            font-size: 18px;
                        }
                    }
                }

                .content {
                    flex: 1;
                    // padding-left: 20px;

                    .row-pos {
                        display: flex;
                    }
                    .content-tranfer {
                        color: #333;
                        font-weight: 600;
                    }
                    .action-time {
                        padding: 1px 0px;
                        font-size: 12px;
                        color: #a5a5a5;
                    }
                    .note {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        max-width: 285px;
                    }
                    .bottom {
                        display: flex;
                        align-items: center;
                        .info-item {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            flex: 4;
                            max-width: 285px;
                        }
                        // .status-pos {
                        //     flex: 1;
                        // }
                        .value-pos {
                            font-weight: 600;
                            flex: 1;
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
    .report {
        border-top: 1px solid #a8aeb3 !important;

        padding: 0px 20px;

        display: flex;
        flex-direction: column;
        .title {
            color: #333;
            font-size: 20px;
            font-weight: 600;
            padding: 0;
            padding-top: 5px;
        }
        .dollar {
            font-size: 13px;
            position: absolute;
            left: -10px;
            top: 5px;
        }
        .report-money {
            flex: 1;
            display: flex;
            padding-bottom: 10px;
            padding-top: 5px;
            .report-recieved {
                display: flex;
                flex-direction: column;
                align-items: center;
                border-right: 1px solid #a8aeb3;

                flex: 1;
                .value {
                    font-size: 20px;
                    color: var(--main-color-blue-ocean);
                    font-weight: 600;
                    position: relative;
                    display: flex;
                    align-items: center;
                }
                .description {
                    font-size: 14px;
                }
            }
            .icon {
                font-size: 14px;
                padding: 0px 2px;
            }

            .report-take {
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1;
                .value {
                    font-size: 20px;
                    color: var(--main-color-red);
                    font-weight: 600;
                    position: relative;
                    display: flex;
                    align-items: center;
                }
                .description {
                    font-size: 14px;
                }
            }
        }
        .total-rest {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 10px;
            .des-total {
                font-size: 14px;
            }
            .value {
                font-size: 20px;
                color: var(--main-color-green);
                font-weight: 600;
                position: relative;
                display: flex;
                align-items: center;
            }
        }
    }
}
