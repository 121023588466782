.new-booking-drawer-cpn {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 29px 22px;
    .search {
        .search-box {
            border: 0 !important;
            background: #ffffff;
            box-shadow: 0px 0px 10px #c1e4ff;
            border-radius: 3px;
            height: 36px !important;
            line-height: 36px;
            .input-search-box {
                height: 36px !important;
                border: 0 !important;
                line-height: 36px;
                &:focus {
                    box-shadow: none !important;
                }
            }
            .wrap-con-field {
                height: 100% !important;
                > span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 36px;
                }
            }
            &:hover {
                box-shadow: 0px 0px 10px #c1e4ff !important;
            }
            &:focus-within {
                box-shadow: 0px 0px 10px #9dcbee !important;
            }
        }
    }
    .filter {
        display: flex;
        align-items: center;
        padding-top: 15px;
        line-height: 36px;

        .date {
            margin-right: 10px;
            font-size: 14px;
            color: #272829;
            margin-bottom: 2px;
        }
        .today {
            width: 72px;
            height: 100%;
            .today-date {
                box-shadow: 0px 0px 10px #c1e4ff;
                border-radius: 3px;
                border: 0 !important;
                width: 100%;
            }
            .btn-date-filer {
                border-radius: 3px;
                min-width: 34px;
                text-align: center;
                color: #333;
                background: #fff;
                cursor: pointer;
                height: 100% !important;
                margin-top: 0px;
                &:hover {
                    color: var(--main-color-blue-light);
                    border-color: var(--main-color-blue-light);
                }
                &:focus {
                    outline: unset !important;
                }
            }
        }
        .date-cpn-filter {
            flex: 1;
            min-width: 195px;
            margin-left: 12px;
            height: 100%;
            box-shadow: 0px 0px 10px #c1e4ff;
            border-radius: 3px;
            background: #fff;
            display: flex;
            align-items: center;
            .wrap-custom-date {
                display: flex;
                flex: 1;
                border: 0 !important;
                .date-picker-custom {
                    width: 100px !important;
                }
                .icon-field-line {
                    display: none !important;
                }
                .react-datepicker__input-container {
                    text-align: center;
                }
            }
            .date-single {
                border: 0 !important;
                border-radius: 0;
                display: flex;
                align-items: center;
                &:focus {
                    outline: 0 !important;
                }
                &:focus-within {
                    box-shadow: unset !important;
                    border-color: 0 !important;
                }
                .anticon-calendar {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .prev,
            .next {
                height: 100%;
                display: flex;
                align-items: center;
                width: 30px;
                justify-content: center;
                cursor: pointer;
                font-size: 12px;
                &:hover {
                    color: var(--main-color);
                }
            }
        }
    }
    .sort {
        display: flex;
        justify-content: flex-end;
        padding-top: 15px;
        .sort-icon {
            width: 28px;
            box-shadow: 0px 0px 10px #c1e4ff;

            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            > i {
                font-size: 16px;
                color: #969696;
            }
        }
    }
    .list-bookings-new {
        flex: 1;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        border-top: 1px solid #a8aeb3;
        overflow: auto;
        margin-bottom: 50px;
        .active {
            background: #e7f6ff;
        }
        .item-booking {
            display: flex;
            cursor: pointer;
            padding: 12px 20px;
            &:hover {
                background: #e7f6ff;
            }
            &:not(:first-of-type) {
                border-top: 1px dashed #a8aeb3 !important;
            }
            .content-left {
                flex: 1;
                display: flex;
                flex-direction: column;

                .title-value {
                }
                .highlight-value {
                    font-weight: 600;
                    color: var(--main-color-red);
                }
                .highlight-blue {
                    font-weight: 600;
                    color: var(--main-color-blue-light);
                }
                .highlight-technician {
                    color: #7a25ea;
                    font-weight: 600;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    flex: 1;
                }

                .highlight-description {
                    font-weight: 600;
                    color: var(--main-color-red);
                    text-transform: uppercase;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    flex: 1;
                }
            }
            .content-right {
            }
        }
    }
    .list-bookings-new::-webkit-scrollbar-thumb {
        background: #a8aeb3 !important;
        opacity: 0.5;
        border-radius: 10px;
        width: 0.5px !important;
    }
    .list-bookings-new::-webkit-scrollbar {
        width: 4px;
    }

    .nothing-found {
        font-weight: 600;
        font-size: 20px;
        text-align: center;
    }
    .schedule-status {
        color: #fff;
        background: #ba81d3;
        border-radius: 3px;
        padding: 4px 8px;
        font-weight: 400;
        &.NEW {
            color: #fff;
        }
    }
}
.menu-item {
    .custom-cbx {
        margin-right: 10px;
    }
    .label {
        margin-top: 3px;
        flex: 1;
        margin-right: 10px;
    }
    .default {
        color: #bfbfbf;
    }
    .asc {
        .anticon-caret-up {
            color: var(--main-color-blue-ocean);
        }
    }
    .desc {
        .anticon-caret-down {
            color: var(--main-color-blue-ocean);
        }
    }
    .sort-arrow {
        display: flex;
        flex-direction: column;
        margin-top: 5px;

        .anticon-caret-up {
            margin-bottom: -4px;
            font-size: 11px;
        }
        .anticon-caret-down {
            font-size: 11px;
        }
    }
}
.selected {
    color: var(--main-color);
    background-color: #e6f7ff;
}
.item-bottom {
    border-top: 1px solid #f0f0f0;
    margin-top: 5px;

    &:hover {
        background-color: #fff !important;
        cursor: inherit !important;
    }
    .action-sort {
        display: flex;
        align-items: center;

        .button-rs {
            width: 40px;
        }
        .button-ok {
            color: #fff;
            background: #1890ff;
            border-color: #1890ff;
            cursor: pointer;
            padding: 2px 7px;
            border-radius: 3px;
            &:hover {
                text-decoration: none;
                color: #fff;
                background: #40a9ff;
                border-color: #40a9ff;
            }
        }
        .disabled {
            cursor: not-allowed;
            color: rgba(0, 0, 0, 0.25);
        }
        .enable {
            color: var(--main-color);
            cursor: pointer;
        }
    }
}
