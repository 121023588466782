.dashboard-v2 {
    .wrap-dashboard-v2 {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        box-shadow: 0px 0px 10px #c1e4ff;
        border-radius: 3px;

        .content-header-wrap {
            border-radius: 3px;
            .content-schedules {
                height: 40px;
                margin-left: 10px;
                margin-right: 10px;
                border-bottom: 1px solid #a8aeb3;
                > div {
                    height: 100%;
                }
            }
            .header-time-schedule {
                height: 100%;
                > li {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .text-time {
                        background: transparent !important;
                        color: #006096 !important;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: unset !important;
                        height: unset !important;
                        margin: 0 !important;
                    }
                }
            }
            .left-content-schedules-booking {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: -10px;
                margin-right: 0px !important;
                width: 92px !important;
                .title {
                    line-height: unset !important;
                    height: unset !important;
                }
            }
            .right-content-header-schedules-booking {
                margin-left: 0px !important;
            }
            .header-time-schedule {
                background: transparent;
                border: 0 !important;
            }
            .title {
                background: #fff !important;
                border: none;
            }
            .time-schedule {
                background: #fff !important;
            }
        }
    }
    .content-schedules {
        position: relative;
        height: calc(100vh - 185px);
        .bookings {
            width: 92px !important;
            border-right: 1px solid #a8aeb3 !important;
            .li-bookings {
                background: #fff !important;
                border: 0 !important;
                height: 122px !important;
                margin-left: 10px !important;
                padding: 0px 10px 0px 0px !important;
                line-height: unset !important;
                display: flex;
                align-items: center;
                flex-direction: column;
                &:not(:first-of-type) {
                    border-top: 1px solid #a8aeb3 !important;
                }
                .total-booking {
                    margin: 0 !important;
                    padding: 0 !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 25px;
                    height: 19px;
                    line-height: unset !important;
                    top: unset !important;
                    bottom: 0 !important;
                    border: 0 !important;
                    background: #2fa4e7;
                }
                .time-booking {
                    line-height: unset !important;
                    height: 54px;
                    padding-bottom: 6px;
                    align-items: flex-end;
                    display: flex;
                    justify-content: center;
                }
                .booking-in-time {
                    // top: 50px !important;
                    // left: -5px !important;
                    flex: 1;
                    line-height: unset !important;
                    position: relative !important;
                    top: unset !important;
                    left: unset !important;
                    .action-in-time {
                        display: flex;
                        justify-content: center;
                    }
                    .btn-kiritm-create-booking {
                        width: 62px !important;
                        border-radius: 5px;
                        background: var(--main-color-green);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        > svg {
                            fill: #fff;
                        }
                        &:hover {
                            > svg {
                                fill: var(--main-color-green);
                            }
                        }
                    }
                }
            }
        }
        .left-content-schedules-booking {
            margin-right: 0px !important;
            .scroll-mark {
                overflow: scroll !important;
                height: 9px !important;
            }
        }
        .right-content-schedules-booking {
            height: calc(100vh - 185px);
            margin-left: 0px !important;
            .li-schedules-booking {
                border-top: 1px solid #a8aeb3 !important;
                background: #fff !important;
                &:first-of-type {
                    border-top: 0 !important;
                }
                .time-schedule-row {
                    .time-schedule-col {
                        border-right: 1px solid #a8aeb3;
                        border-left: 0 !important;
                    }
                }

                .list-booking-in-time {
                    .booking-in-time-wrap {
                        height: 100%;
                        padding: 4px;
                        .booking-in-time {
                            display: flex;
                            .left-booking-in-time {
                                display: flex;
                                flex-direction: column;
                                flex: 1;
                                overflow: hidden;
                                .title-value {
                                    padding-right: 10px;
                                    display: flex;
                                    .title-in-db {
                                        width: 52px;
                                    }
                                }
                            }
                            .col-action {
                                width: 36px !important;
                                padding: 0 !important;
                                height: 100% !important;
                                .list-action-booking {
                                    display: flex;
                                    flex-direction: column;
                                    height: 100%;
                                    justify-content: flex-end;

                                    .btn-kiritm-action {
                                        width: 28px;
                                        height: 28px;
                                        margin-bottom: 0px !important;
                                        margin-top: 5px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        > svg {
                                            fill: white;
                                        }
                                        &:focus {
                                            outline: 0 !important;
                                        }
                                        &:hover {
                                            > svg {
                                                fill: var(--main-color-blue-light);
                                            }
                                        }
                                    }
                                    .btn-kiritm-cancel {
                                        &:hover {
                                            > svg {
                                                fill: var(--main-color-red) !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .btn-kiritm-create-booking,
    .btn-date-filer {
        &:focus {
            outline: unset !important;
        }
    }
    .header-schedules {
        display: flex;
        margin: 0;
        align-items: center;
        height: 36px;
        > div {
            height: 100%;
        }
        .filter-left {
            display: flex;
            .btn-date-filer {
                height: 100% !important;
                margin-top: 0px;
            }
            .ant-select-selector {
                padding: 0px 20px;
                height: 36px !important;
                box-shadow: 0px 0px 10px #c1e4ff;
                border-radius: 3px;
                border: 0 !important;
                .ant-select-selection-search {
                    height: 36px;
                    .ant-select-selection-search-input {
                        height: 100%;
                        padding: 0px 8px;
                    }
                }
                .ant-select-selection-placeholder {
                    padding-top: 2px;
                }

                .item-info-booking {
                    display: flex;
                    border: 0 !important;
                    align-items: center;
                    height: 100%;
                    padding: 0px !important;
                    .text {
                        width: unset !important;
                        line-height: unset !important;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        margin: 0 !important;
                        padding: 0 !important;
                        .text-code,
                        .text-status,
                        .text-name,
                        .text-phone {
                            height: 100%;
                            display: flex;
                            align-items: center;
                            margin-right: 10px;
                        }
                    }
                    .display-none {
                        display: none;
                    }
                    .display-block {
                        display: block;
                        margin-left: 10px;
                    }
                }
            }
            .today {
                width: 72px;
                height: 100%;
                .today-date {
                    box-shadow: 0px 0px 10px #c1e4ff;
                    border-radius: 3px;
                    border: 0 !important;
                    width: 100%;
                }
            }
            .date-cpn-filter {
                min-width: 195px;
                margin-left: 12px;
                height: 100%;
                box-shadow: 0px 0px 10px #c1e4ff;
                border-radius: 3px;
                background: #fff;
                display: flex;
                align-items: center;
                .wrap-custom-date {
                    border: 0 !important;
                    .date-picker-custom {
                        width: 100px !important;
                    }
                    .icon-field-line {
                        display: none;
                    }
                }
                .date-single {
                    border: 0 !important;
                    border-radius: 0;
                    display: flex;
                    align-items: center;
                    &:focus {
                        outline: 0 !important;
                    }
                    &:focus-within {
                        box-shadow: unset !important;
                        border-color: 0 !important;
                    }
                    .anticon-calendar {
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
                .prev,
                .next {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    width: 30px;
                    justify-content: center;
                    cursor: pointer;
                    font-size: 12px;
                    &:hover {
                        color: var(--main-color);
                    }
                }
            }
        }
        .filter-right {
            .filter-branch {
                box-shadow: 0px 0px 10px #c1e4ff;
                border-radius: 3px;
                margin-right: 10px;
                .body-wrap {
                    border: 0 !important;
                    height: 100%;
                }
            }
        }
    }
}
.customer-note-info {
    .ant-timeline-item-last {
        padding-bottom: 0px;
    }
    .ant-timeline-item-content {
        min-height: 20px;
    }
    .ant-popover-inner {
        max-width: 250px;
        border-radius: 3px;
    }
}
.search-booking-overlay {
    padding-top: 0 !important;
    padding-bottom: 10px;
    .rc-virtual-list-holder {
        max-height: 428px !important;
    }

    .option-kiritmz {
        font-size: 12px !important;
        &:first-of-type {
            .item-info-booking {
                border: 0 !important;
            }
        }
        .item-info-booking {
            padding: 7px 5px 0px !important;
        }
    }
    .ant-select-item-option-content {
    }
}
