$main-color: var(--main-color);
$main-background-color: var(--main-background-color);
$background-footer: var(--background-footer);
$background-header: var(--background-header);
$color-footer: var(--color-footer);
$background-side-menu: var(--background-side-menu);
$color-side-menu: #333;
//$color-active-side-menu: var(--color-active-side-menu);
$color-active-side-menu: #046db3;
$background-active-side-menu: #bee6f7;
$color-icon-side-menu: var(--color-icon-side-menu);
$color-header: var(--color-header);
$height-row-side-menu: 48px;
$height-button: var(--button-height);

.header-page {
    .menu-header {
        width: 160px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: var(--main-color);
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
    }
    .menu-header.active {
        background: #026eaa;
        color: #fff;
    }
    .menu-header:hover {
        background: #026eaa;
        color: #fff;
    }
}

.logo {
    height: 40px;
    margin-right: 103px;
}

.toolbar-action-bottom {
    display: flex;
    position: fixed;
    z-index: 100;
    bottom: 20px;
    left: calc(50%);

    .btn-action-bottom {
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        min-width: 100px;
        margin: 0 5px;

        > i {
            margin-right: 7px;
        }
    }
}

.side-menu-page {
    font-size: 12px;
    user-select: none;
    background: transparent !important;
    box-shadow: none;
    z-index: 1;

    div {
        &::-webkit-scrollbar {
            width: 6px !important; //dọc
            height: 6px !important; //ngang
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent !important;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgb(82, 183, 241) !important;
            opacity: 0.8;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            opacity: 1;
        }
    }

    .ant-layout-sider-children {
        background: white;

        .side-menu-line {
            span {
                background: $color-active-side-menu;
            }

            i {
                color: $color-active-side-menu;
            }
        }

        .logo-background {
            height: 100%;
            background-image: url("../../images/side-menu.jpg");
            background-size: 100%;
        }
        .side-menu {
            overflow-y: auto;
            overflow-x: hidden;
            background: transparent;
            color: $color-side-menu;
            width: 100%;
            border-color: transparent !important;
            margin-top: 0 !important;
            font-size: 13px;

            .ant-menu-submenu > .ant-menu {
                background: transparent;
                font-size: 13px;
            }

            .ant-menu-item {
                margin: 0;
                padding: 0px !important;
                color: $color-side-menu;
                height: 48px;
                font-size: 13px;

                .route-side-menu {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    color: $color-side-menu;
                    font-size: 13px;
                    line-height: 48px;

                    .side-menu-icon-wrap {
                        width: 54px;
                        height: 100%;
                        display: flex !important;
                        align-items: center;
                        justify-content: center;
                        font-size: 13px;

                        .side-menu-icon {
                            width: 30px;
                            height: 30px !important;
                            border-radius: 5px;
                            color: #fff !important;
                            line-height: 30px !important;
                            text-align: center;
                        }
                    }

                    .side-menu-text {
                        flex: 1;
                        white-space: nowrap;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                        width: calc(100% - 55px);
                        font-size: 13px;
                    }
                }

                &.ant-menu-item-selected {
                    > a {
                        color: $color-active-side-menu !important;
                    }
                }

                &.ant-menu-item-selected,
                &.ant-menu-item-active {
                    color: $color-active-side-menu !important;
                    background: $background-active-side-menu !important;
                    font-size: 13px;

                    .anticon {
                        color: $color-active-side-menu !important;
                    }
                }

                &:last-child {
                    border-bottom: 0;
                }

                .anticon {
                    float: left;
                    display: flex;
                    height: 100%;
                    align-items: center;
                    font-size: 20px;
                    color: $color-icon-side-menu;
                }

                &::after {
                    right: unset !important;
                    border-color: transparent;
                }

                &:not(:last-child) {
                    margin-bottom: 0px !important;
                }
            }

            .ant-menu-submenu {
                background: transparent;
                color: $color-side-menu;

                .ant-menu-sub {
                    .route-side-menu {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        padding-left: 20px;
                        line-height: 48px;
                        font-size: 13px;
                    }
                }

                .ant-menu-submenu-title {
                    margin: 0;
                    background: transparent;
                    color: $color-side-menu;
                    height: 48px !important;
                    padding: 0px !important;

                    &:hover {
                        background: $background-active-side-menu;
                        color: $color-active-side-menu;
                    }

                    .route-side-menu {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        line-height: 48px;
                    }

                    .anticon {
                        float: left;
                        display: flex;
                        height: 100%;
                        align-items: center;
                        font-size: 20px;
                        color: $color-icon-side-menu;
                    }
                }

                .side-menu-icon-wrap {
                    width: 54px !important;
                    height: 100%;
                    display: flex !important;
                    align-items: center;
                    justify-content: center;

                    .side-menu-icon {
                        width: 30px;
                        height: 30px !important;
                        border-radius: 5px;
                        color: #fff !important;
                        line-height: 30px !important;
                        text-align: center;
                    }
                }

                .side-menu-text {
                    flex: 1;
                    width: calc(100% - 84px);
                    white-space: nowrap;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                    font-size: 13px;
                }

                .ant-menu-submenu-arrow {
                    right: 10px !important;
                }
            }

            //
            /* width - height */
        }
    }
}
.status-booking {
    border-radius: 3px;
    text-align: center;
    padding: 2px 4px;
    width: 70px;
    display: block;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.Waiting {
        border: 1px solid #ff8c00;
        color: #ff8c00;
    }

    &.Checkedin {
        border: 1px solid #ff4a00;
        color: #ff4a00;
    }

    &.Processing {
        border: 1px solid #2196f3;
        color: #2196f3;
    }

    &.Completed {
        border: 1px solid #4caf50;
        color: #4caf50;
    }
    &.Cancel {
        border: 1px solid #c62828;
        color: #c62828;
    }
    &.NoShow {
        border: 1px solid #888;
        color: #888;
    }
    &.NEW {
        border: 1px solid #ba81d3;
        color: #ba81d3;
    }
}
.status {
    padding: 3px 0px;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    // width: 100%;
    text-align: center;
    line-height: 16px;
    display: block;
    // margin: 0px auto;
    text-align: center;
    display: flex;

    .box-custom {
        // this box for custom color background
        padding: 8px;
        border-radius: 1px;
        margin-right: 6px;
    }

    .box-new {
        padding: 8px;
        border-radius: 1px;
        margin-right: 6px;
        height: 16px;
        background: #337ab7;
    }

    .box-denied {
        padding: 8px;
        border-radius: 1px;
        margin-right: 6px;
        background: #f44336;
    }

    .box-complete {
        padding: 8px;
        border-radius: 1px;
        margin-right: 6px;
        background: #5cb85c;
    }

    .box-progress {
        padding: 8px;
        border-radius: 1px;
        margin-right: 6px;
        background: #f0ad4e;
    }

    .box-cancel {
        padding: 8px;
        border-radius: 1px;
        margin-right: 6px;
        background: #aaadb7;
    }

    .box-progress {
        padding: 8px;
        border-radius: 1px;
        margin-right: 6px;
        background: #77b767;
    }

    .box-prepare {
        padding: 8px;
        border-radius: 1px;
        margin-right: 6px;
        background: #b7ae58;
    }

    &.status-new {
        border: 1px solid #337ab7;
        color: #337ab7;
    }

    &.status-denied {
        border: 1px solid #f44336;
        color: #f44336;
    }

    &.status-complete {
        border: 1px solid #5cb85c;
        color: #5cb85c;
    }

    &.status-progress {
        border: 1px solid #f0ad4e;
        color: #f0ad4e;
    }

    &.status-cancel {
        border: 1px solid #aaadb7;
        color: #aaadb7;
    }
}

.status-ptw-header {
    margin: 4px 5px;
    line-height: 22px;
    padding: 0 8px;
    font-size: 11px;
    border-radius: 3px;
    float: right;
    color: rgb(255, 152, 0);
    border: 1px solid rgb(255, 152, 0);
}

.status-ptw-header-approval {
    margin: 4px 5px;
    line-height: 22px;
    padding: 0 8px;
    font-size: 11px;
    border-radius: 3px;
    float: right;
    color: rgb(76, 175, 80);
    border: 1px solid rgb(139, 195, 74);
}

.status-ptw-header-new {
    margin: 4px 5px;
    line-height: 22px;
    padding: 0 8px;
    font-size: 11px;
    border-radius: 3px;
    float: right;
    color: rgb(33, 150, 243);
    border: 1px solid rgb(33, 150, 243);
}

.status-ptw-header-done {
    margin: 4px 5px;
    line-height: 22px;
    padding: 0 8px;
    font-size: 11px;
    border-radius: 3px;
    float: right;
    color: rgb(255, 77, 79);
    border: 1px solid rgb(255, 77, 79);
}

.custom-menu-page {
    .ant-layout-sider-children {
        .side-menu {
            .ant-menu-submenu {
                .ant-menu-item {
                    .route-side-menu {
                        padding-left: 0px !important;
                        line-height: 48px;
                        font-size: 13px;

                        .side-menu-icon-wrap {
                            > i {
                                position: relative;
                                height: 100%;
                                background: #046db3;
                                width: 1px;

                                &::before {
                                    content: "";
                                    width: 10px;
                                    height: 10px;
                                    border: 1px solid #046db3;
                                    background: #fff;
                                    display: block;
                                    left: -5px;
                                    position: absolute;
                                    top: 19px;
                                    z-index: 9;
                                    -webkit-transform: rotate(45deg);
                                    transform: rotate(45deg);
                                }

                                &::after {
                                    content: "";
                                    width: 20px;
                                    height: 1px;
                                    border-radius: 50%;
                                    background: #046db3;
                                    display: block;
                                    left: 0;
                                    position: absolute;
                                    top: 24px;
                                    z-index: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.metro-sidebar {
    height: 44px;
    display: flex;
    background: #fff;

    .box-menu {
        flex: 1;
        display: flex;
        flex-direction: column;
        color: #989898;
        border-bottom: 2px solid transparent;
        cursor: pointer;

        &.active {
            > i {
                color: rgba(255, 255, 255, 0.2);
            }

            border-bottom: rgba(255, 255, 255, 0.2);
        }

        > i {
            line-height: 22px;
            height: 22px;
            text-align: center;
            font-size: 15px;
            padding: 3px 0 0;
            margin: 0;
        }

        > span {
            flex: 1;
            text-align: center;
            font-size: 10px;
            line-height: 17px;
        }
    }
}

.menubar {
    flex: 1;
    display: flex;
    // background: linear-gradient(180deg, #45C8FB 0%, rgba(222, 222, 222, 0.36) 38.54%);

    .box-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #fff;
        padding: 10px 8px 5px 10px;
        margin: 0px 0px 0 0;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        // background: rgba(255, 255, 255, 0.2);

        // &.active {
        //   // >i{
        //   //     color: #fff;
        //   // }
        //   // border-bottom: 2px solid #fff;
        //   padding-bottom: 4px;
        //   border-bottom-color: #0088fe;
        // }
        &.active {
            background: rgba(255, 255, 255, 0.2);
        }

        &:hover {
            background: rgba(255, 255, 255, 0.2);
        }

        // > i {
        //   height: 15px;
        //   text-align: center;
        //   font-size: 14px;
        //   padding: 2px 0 0;
        //   margin: 0;
        // }
        > img {
            height: 14px;
            width: 14px;
            text-align: center;
            padding: 2px 0 0;
            margin: 0 auto;
        }

        > span {
            flex: 1;
            height: 19px;
            text-align: center;
            font-size: 13px;
            line-height: 19px;
            font-weight: 500;
        }
    }
}

.end-menu {
    display: inline-flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
}

.route-side-menu {
    display: inline-block !important;
    width: 100%;
    overflow: hidden;
    line-height: 48px;
    font-size: 13px;

    .side-menu-text {
        flex: 1;
        line-height: 48px !important;
        display: inline-block;
        position: relative;
    }

    .side-menu-icon-wrap {
        font-size: 16px;
        float: left;
    }
}

.side-menu-title {
    color: $color-active-side-menu;

    .header-side-bar {
        cursor: pointer;
        position: relative;
        font-size: 25px;
        font-weight: 600;
        width: 150px;
        display: block;
        margin: 0 auto;
        line-height: 38px;
        clear: both;

        > img {
            margin-right: 5px;
            float: left;
        }

        .header-text {
            position: absolute;
            left: 45px;
            line-height: 38px;
        }

        .header-text-backgroud {
            color: #fff;
            line-height: 38px;
        }
    }
}

.side-menu-icon-sub {
    width: 26px;
    height: 26px !important;
    border-radius: 5px;
    color: #fff !important;
    line-height: 26px !important;
    text-align: center;
    background: #607d8b;
    font-size: 12px;
}

.ant-menu-item-selected::after {
    right: unset;
    left: 0;
    border-right: 3px solid $color-active-side-menu !important;
}

.btn {
    text-transform: uppercase;
}

.ant-tooltip-inner {
    .route-side-menu {
        line-height: 48px;
        display: flex !important;
        font-size: 13px;

        .side-menu-icon-wrap {
            display: none;
        }
    }
}

.ant-tooltip {
    .side-menu-text {
        flex: 1;
        line-height: unset !important;
    }
}

.ant-menu-submenu-popup {
    padding-left: 2px;
    background: transparent !important;
    display: none !important;

    .side-menu-icon-wrap {
        width: 25px;
    }

    .side-menu-text {
        flex: 1;
        line-height: 38px !important;
    }

    .ant-menu-item {
        height: 38px !important;
        margin: 0px !important;
    }

    .ant-menu-sub::before {
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: 6px solid #fff;
        position: absolute;
        left: -3px;
        top: 5px;
    }
}

.ant-layout-sider-collapsed {
    .side-menu-text {
        padding: 0px !important;
    }
}

.header-page {
    display: flex;
    background: $background-header !important;
    color: $color-header !important;

    .btn-toggle-menu {
        width: 50px;
        height: 50px;
        font-weight: 600;
        line-height: 50px;
        text-align: center;
        cursor: pointer;

        > i {
            font-size: 20px;
            color: #0092d9;
            line-height: 50px;
        }
    }

    .title-header {
        color: #fff !important;
        font-size: 13px;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        text-transform: uppercase;
        overflow: hidden;

        .app-unit {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }

        .app-name {
            font-style: italic;
            font-size: 12px;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }
    }

    .anticon {
        color: $color-header !important;
    }

    //   .breadcrumb {
    //     padding: 0 10px;
    //     line-height: 50px;
    //     font-size: 16px;
    //     background: transparent;
    //     color: $color-header;
    //     text-transform: uppercase;
    //     margin-bottom: unset !important;
    //   }
}

.breadcrumb {
    padding: 5px 2px 0px !important;
    line-height: 50px;
    background: transparent !important;
    color: #fff;
    margin-bottom: unset !important;

    .breadcrumb-box {
        justify-content: flex-start !important;
        display: flex;
        flex-direction: row;
        line-height: 20px;
        margin-left: 12px;

        .breadcrumb-list {
            font-weight: 500;
            padding: 5px 0px 0px;
            font-size: 13px;
        }
    }
}

.body-page {
    //padding-top: 10px;
    display: flex;
    width: 100%;
    position: relative;
    min-height: 100%;
    flex-direction: column;

    .content-body-page {
        border-radius: 3px;
        background: #fff;
        box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1);
        position: relative;
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 10px;
        height: 100%;
        position: relative;

        .ant-tabs-bar {
            margin-bottom: 0;
        }

        .base-collapse {
            .header-base-collapse {
                margin: 10px;
                border-bottom: 0;

                .ant-collapse-header {
                    border-radius: 5px;
                    background: linear-gradient(180deg, #00b8ff 0%, rgba(222, 222, 222, 0.36) 38.54%);

                    > i {
                        color: white;
                        margin-top: -1px;
                    }

                    > span {
                        color: white;
                    }
                }
            }
        }

        .base-tab-list-view {
            flex: 1;
            display: flex;
            flex-direction: column;

            > .ant-tabs-content {
                flex: 1;
                display: flex;
                flex-direction: column;
                position: relative;
                height: 100%;

                > .ant-tabs-tabpane-active {
                    height: 100%;
                    top: 0;
                }
            }
        }

        .content-title {
            .content-title-top {
                line-height: 30px;
                text-align: center;
                font-size: 18px;
                color: var(--color-active-side-menu);
                margin-bottom: 15px;
            }

            .content-title-bottom {
                line-height: 30px;
                text-align: center;
                font-size: 22px;
                font-weight: 600;
                color: var(--color-active-side-menu);
                text-transform: uppercase;
                margin-bottom: 15px;
            }

            .title-kpi-period {
                font-size: 13px;
                color: $color-active-side-menu;
                text-transform: uppercase;
                line-height: 32px;

                .box-status-kpi-form {
                    margin: 0 8px;
                    display: flex;
                    float: right;

                    .label {
                        color: #333;
                        text-transform: none;
                        font-weight: 600;
                        padding-right: 5px;
                    }

                    .status-kpi-form {
                        span {
                            margin: 4px 5px;
                            line-height: 22px;
                            padding: 0 8px;
                            font-size: 11px;
                            border-radius: 3px;
                            float: right;
                        }
                    }
                }
            }
        }

        .filter-template-report {
            display: flex;

            .title-filter {
                font-size: 18px;
                text-align: left;
                font-weight: 500;
                text-transform: uppercase;
                flex: 1 1 0%;
                margin-right: 20px;
                line-height: 30px;
                color: #000;
            }

            .row-field {
                margin-bottom: 0;
            }

            .btn-download {
                color: #2e7d32;
                background-color: #fff;
                border-color: #2e7d32;
                display: flex;
                padding: 3px 8px;
                color: #2e7d32;
                font-size: 12px;
                line-height: 22px;
                margin-right: 10px;

                > i {
                    margin: 4px 6px 4px 0;
                    font-size: 16px;
                    color: #2e7d32;
                }

                &:hover {
                    color: #fff;
                    background: #2e7d32;

                    > i {
                        background-color: #2e7d32;
                        color: #fff;
                    }
                }
            }

            .btn-download-word {
                color: #096dd9;
                background-color: #fff;
                border-color: #096dd9;
                display: flex;
                padding: 3px 8px;
                color: #096dd9;
                font-size: 12px;
                line-height: 22px;
                margin-right: 10px;

                > i {
                    margin: 4px 6px 4px 0;
                    font-size: 16px;
                    color: #096dd9;
                }

                &:hover {
                    color: #fff;
                    background: #096dd9;

                    > i {
                        background-color: #096dd9;
                        color: #fff;
                    }
                }
            }
        }

        .filter-ktcn {
            display: flex;

            .title-filter {
                font-size: 18px;
                text-align: left;
                font-weight: 500;
                text-transform: uppercase;
                flex: 1 1 0%;
                margin-right: 20px;
                line-height: 30px;
                color: #000;
            }
        }

        .doc-top-tab-toolbar {
            display: flex;
            flex: 1;
            padding: 15px 0 0 0;

            .btn-tab {
                margin-right: 15px;

                .btn {
                    border-radius: 0;
                    font-size: 12px;
                    text-transform: uppercase;
                    padding: 6px 10px;
                    line-height: 20px;

                    &.btn-default {
                        background: #f1f1f1;
                        border: 1px solid #ccc;
                        color: #0092d9;
                    }
                }
            }

            .right-filter-toolbar {
                flex: 1;
                text-align: right;
            }
        }
    }

    .button-action-bar {
        min-width: unset !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.button-action-bar {
    min-width: unset !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-back {
    background-color: rgb(221, 221, 221);
    border-color: transparent;
}

.btn-full-screen {
    .ant-btn {
        > i {
            margin-top: -3px;
        }
    }
}

.side-menu-bagde {
    position: absolute;
    right: 20px;
    top: 8px;
    background: var(--main-color-red);
    display: block;
    height: 18px;
    padding: 1px 3px;
    min-width: 18px;
    text-align: center;
    border-radius: 50%;
    overflow: hidden;
    line-height: 13px;
    border: 1px solid var(--main-color-red);
    color: #fff;
    font-size: 11px;
}

.badge-total {
    position: absolute;
    right: 20px;
    top: 8px;
    background: var(--main-color-red);
    display: block;
    height: 18px;
    padding: 1px 3px;
    min-width: 18px;
    text-align: center;
    border-radius: 50%;
    overflow: hidden;
    line-height: 13px;
    border: 1px solid var(--main-color-red);
    color: #fff;
    font-size: 11px;
}

// tile + filter base

.title-filter-view {
    display: flex;

    .title-view {
        font-size: 18px;
        text-align: left;
        font-weight: 500;
        text-transform: uppercase;
        flex: 1 1 0%;
        margin-right: 20px;
        line-height: 30px;
        color: $color-active-side-menu;
    }

    .row-field {
        margin-bottom: 0;
    }

    .filter-view {
        min-width: 300px;

        .col-filter {
            flex: 1;
        }

        .color-field-select {
            .label-field {
                color: #ffffff;
            }

            .field-wrap {
                border: 1px solid #fff;

                .time-widget__control {
                    font-weight: 500;

                    .time-widget__placeholder {
                        color: #ffffff !important;
                    }

                    .time-widget__single-value {
                        color: #ffffff !important;
                    }

                    background-color: transparent;

                    .time-widget__indicators {
                        .icon-remove {
                            padding-right: 10px !important;
                        }

                        .wrap-con-field {
                            .icon-field {
                                color: #ffffff !important;
                            }
                        }
                    }
                }

                .time-widget__menu {
                    color: #333;
                }
            }

            .icon-remove {
                width: 10px !important;
            }

            background-color: transparent;

            .singleMonth-picker {
                border: 1px solid #fff;
                background: transparent;

                .icon-remove {
                    padding-right: 10px !important;
                }

                .month-picker-input {
                    background: transparent;
                    color: #ffffff !important;
                }

                .wrap-con-field {
                    color: #ffffff !important;

                    .icon-field {
                        color: #ffffff !important;
                    }
                }
            }
        }

        .color-field-select-1 {
            background-color: transparent !important;
            padding-top: 0px !important;

            .label-field {
                color: #ffffff;
            }

            .input-field {
                color: #fff !important;
                background-color: transparent !important;

                .treeSelect {
                    color: #fff !important;
                }

                .field-wrap {
                    width: 250px;
                    border: 1px solid #fff !important;
                    background-color: transparent !important;

                    .rc-tree-select {
                        color: #fff !important;
                        background-color: transparent !important;

                        .rc-tree-select-selection--single {
                            background-color: transparent !important;
                            color: white !important;

                            .rc-tree-select-arrow {
                                .wrap-con-field {
                                    .icon-field {
                                        color: #fff !important;
                                    }

                                    .icon-field-line {
                                        border-left: 1px solid #fff !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .button-approve-all {
        background: #007aff;
        color: #ffffff;
    }

    .button-reject-all {
        background: #fd0000;
        color: #ffffff;
    }
}

//
.custom-grid-filter-bar {
    display: flex;
    margin-bottom: 10px;

    .filter-global {
        width: 350px;
    }

    .cbo-filter-month {
        width: 150px;
        margin-left: 10px;
    }

    .right {
        display: flex;
        flex: 1;
        justify-content: flex-end;
    }

    .btn-transfer {
        margin-left: 10px;
        background-color: #0288d1;
        color: #fff;
        width: 90px;

        &:hover {
            background-color: #4ea3d2;
            color: #fff;
            border-color: transparent;
        }
    }

    .btn-import {
        margin-left: 10px;
        background-color: #00cec9;
        color: #fff;
        width: 90px;

        &:hover {
            background-color: #7ef7f5;
            color: #fff;
            border-color: transparent;
        }
    }

    .btn-success {
        margin-left: 10px;
        background-color: #2ece89;
        color: #fff;
        width: 90px;

        &:hover {
            background-color: #80d1ae;
            color: #fff;
            border-color: transparent;
        }
    }

    .btn-request-status {
        margin-left: 5px;
        background-color: #00cec9;
        color: #fff;

        // width: 40px;
        &:hover {
            background-color: #7ed6df;
            color: #000000;
            border-color: transparent;
        }
    }

    .btn-back {
        margin-left: 5px;
        background-color: #ccc;
        color: #555;

        // width: 40px;
        &:hover {
            background-color: #ddd;
            color: #555;
            border-color: transparent;
        }
    }

    .btn-reject {
        margin-left: 10px;
        background-color: #c62828;
        color: #fff;
        width: 120px;

        &:hover {
            background-color: #e53935;
        }
    }

    .btn-download {
        margin-left: 10px;
        background-color: #959b3e;
        color: #fff;
        width: 120px;

        &:hover {
            background-color: #d8e247;
        }
    }

    .btn-info {
        margin-left: 10px;
        background-color: #17a2b8;
        color: #fff;
        width: 120px;

        &:hover {
            color: #fff;
            background-color: #43becc;
        }
    }
}

.export-excel-btn {
    background-color: #007bff !important;
    border-color: #007bff !important;
    color: #fff !important;
    box-shadow: none !important;
    transition: unset !important;

    &:hover {
        border-color: #007bff !important;
        background: #fff !important;

        i,
        span {
            color: #007bff !important;
        }
    }
}

.btn-refresh {
    height: $height-button !important;
    width: 28px;
    display: flex !important;
    align-items: center !important;

    &:hover {
        border-color: rgb(0, 123, 255) !important;
        background: #fff !important;

        i,
        span {
            color: rgb(0, 123, 255) !important;
        }
    }
}
.btn-refresh-voucher {
    height: 30px !important;
    width: 28px;
    display: flex !important;
    align-items: center !important;
    .anticon {
        line-height: unset;
        display: flex;
    }
    &:hover {
        border-color: rgb(0, 123, 255) !important;
        background: #fff !important;

        i,
        span {
            color: rgb(0, 123, 255) !important;
        }
    }
}
.btn-fullscreen {
    height: $height-button !important;
    width: 28px;
    display: flex !important;
    align-items: center !important;

    &:hover {
        border-color: rgb(0, 123, 255) !important;
        background: #fff !important;

        i,
        span {
            color: rgb(0, 123, 255) !important;
        }
    }
}
.btn-export-report {
    width: 34px;
    height: 32px;
    border-radius: 3px;
    border: 1px solid transparent;
    cursor: pointer;
    outline: 0px;
    //min-width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3.5px;
    color: #fff;
    background-color: var(--main-color-green);
    border-color: var(--main-color-green);

    &:hover {
        color: var(--main-color-green);
        background-color: #fff;
    }
}
.btn-fullscreen-report {
    width: 34px;
    height: 32px;
    border-radius: 3px;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    outline: 0px;
    //min-width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3.5px;
    color: #333;
    background-color: #fff;

    &:hover {
        color: var(--main-color);
        background-color: #fff;
    }
}
.create-btn {
    height: $height-button !important;
    text-transform: uppercase;
    display: flex !important;
    align-items: center;
    background-color: var(--main-color-blue-ocean) !important;
    color: #fff !important;
    box-shadow: none !important;
    transition: unset !important;
    padding: 0 8px !important;
    line-height: 30px !important;
    font-size: 13px !important;

    &:hover {
        border-color: var(--main-color-blue-ocean) !important;
        background: #fff !important;
        color: var(--main-color-blue-ocean) !important;

        i,
        span {
            color: var(--main-color-blue-ocean) !important;
        }
    }

    i,
    span {
        margin-left: 0 !important;
    }

    span {
        font-size: 12px;
    }
}
.button-action-row-disabled {
    opacity: 0.4;
    pointer-events: none;
}
.import-btn {
    height: $height-button !important;
    text-transform: uppercase;
    display: flex !important;
    align-items: center;
    background-color: var(--main-color-green-light) !important;
    color: #fff !important;
    box-shadow: none !important;
    transition: unset !important;
    padding: 0 8px !important;
    line-height: 30px !important;
    font-size: 13px !important;
    &:hover {
        border-color: var(--main-color-green-light) !important;
        background: #fff !important;
        color: var(--main-color-green-light) !important;

        i,
        span {
            color: var(--main-color-green-light) !important;
        }
    }

    i,
    span {
        margin-left: 0 !important;
    }
}

.export-btn {
    height: $height-button !important;
    text-transform: uppercase;
    display: flex !important;
    align-items: center;
    background-color: var(--main-color-green) !important;
    border-color: var(--main-color-green) !important;
    color: #fff !important;
    box-shadow: none !important;
    transition: unset !important;
    padding: 0 8px !important;
    line-height: 30px !important;
    font-size: 13px !important;

    &:hover {
        border-color: var(--main-color-green) !important;
        background: #fff !important;
        color: var(--main-color-green) !important;

        i,
        span {
            color: var(--main-color-green) !important;
        }
    }

    i,
    span {
        margin-left: 0 !important;
    }
}

.cer-btn {
    height: $height-button !important;
    width: 30px;
    text-transform: uppercase;
    display: flex !important;
    align-items: center !important;
    background-color: #b56f12 !important;
    border-color: #b56f12 !important;
    color: #fff !important;
    box-shadow: none !important;
    transition: unset !important;
    padding: 0 8px !important;
    line-height: 30px !important;
    font-size: 15px !important;

    &:hover {
        border-color: #b56f12 !important;
        background: #fff !important;
        color: #b56f12 !important;

        i,
        span {
            color: #b56f12 !important;
        }
    }
}

.clone-btn {
    height: $height-button !important;
    text-transform: uppercase;
    display: flex !important;
    align-items: center !important;
    background-color: #7ca217 !important;
    border-color: #7ca217 !important;
    color: #fff !important;
    box-shadow: none !important;
    transition: unset !important;
    padding: 0 8px !important;
    line-height: 30px !important;
    font-size: 15px !important;

    &:hover {
        border-color: #7ca217 !important;
        background: #fff !important;
        color: #7ca217 !important;

        i,
        span {
            color: #7ca217 !important;
        }
    }

    span:last-child {
        margin-bottom: -2px;
    }
}

.save-error-btn {
    height: $height-button !important;
    text-transform: uppercase;
    display: flex !important;
    align-items: center !important;
    background-color: #ca790b !important;
    border-color: #ca790b !important;
    color: #fff !important;
    box-shadow: none !important;
    transition: unset !important;
    padding: 0 8px !important;
    line-height: 30px !important;
    font-size: 15px !important;

    &:hover {
        border-color: #ca790b !important;
        background: #fff !important;

        i,
        span {
            color: #ca790b !important;
        }
    }

    span:last-child {
        margin-bottom: -2px;
    }
}

.create-btn-dropdown {
    background-color: rgb(0, 123, 255) !important;
    border-color: rgb(0, 123, 255) !important;
    color: #fff !important;
    box-shadow: none !important;
    transition: unset !important;
}
.text-button {
    // font-weight: 600 !important;
    font-size: 13px !important;
    > span {
        margin-right: 5px;
    }
}
.delete-btn {
    height: $height-button !important;
    text-transform: uppercase;
    display: flex !important;
    align-items: center;
    background-color: var(--main-color-red) !important;
    color: #fff !important;
    box-shadow: none !important;
    transition: unset !important;
    padding: 0 8px !important;
    line-height: 30px !important;
    font-size: 13px !important;

    i,
    span {
        margin-left: 0 !important;
    }

    &:hover {
        border-color: var(--main-color-red) !important;
        background: #fff !important;
        color: var(--main-color-red) !important ;
        i,
        span {
            color: var(--main-color-red) !important;
        }
    }
}

.tag {
    background-color: #fff !important;
    padding: 0px !important;
}

.fullscreen-guide {
    position: fixed;
    top: 10px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    pointer-events: none;

    .fullscreen-guide-body {
        display: flex;
        align-items: center;
        font-size: 14px;
        text-transform: none;
        padding: 10px 10px;
        color: #fff;
        background: rgba(0, 0, 0, 0.65);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        pointer-events: none;
        border-radius: 3px;

        .esc {
            border: 1px solid rgb(255, 255, 255);
            display: block;
            border-radius: 3px;
            margin: 0px 10px;
            padding: 0px 5px;
            height: 30px;
            line-height: 30px;
        }
    }
}

.default-status-value {
    text-align: center;
    font-weight: 600;
    width: 70px;

    &.evalueted {
        width: 100%;
    }
}

.tooltiptext-setup-score {
    visibility: hidden;
    width: 120px;
    background-color: #bd2130;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 0px;
    right: -120px;
}

.element-status-default {
    position: relative;

    &:hover {
        .tooltiptext-setup-score {
            visibility: visible;
        }
    }
}

.error-setup-score {
    .textbox {
        border-color: #bd2130 !important;
        color: #bd2130 !important;
    }
}

.content-kpi-dictionary {
    min-width: 100px;

    .row-dictionary {
        display: flex;
        margin-bottom: 6px;

        .label-dictionary {
            flex: 1;
        }

        .input-dictionary {
            width: 80px;
            margin-left: 10px;
        }
    }
}

.cell-value-dictionary {
    width: 100%;
    height: 100%;
    float: left;
    cursor: pointer;

    .row-value-dictionary {
        width: 100%;
        font-size: 12px;
        line-height: 20px;
        display: flex;

        .name {
            flex: 1;
        }

        .value {
            font-weight: 600;
            color: #000;
            width: 20;
        }
    }
}

.ant-popover {
    z-index: 99999 !important;

    .ant-popover-title {
        color: #333;
        font-weight: 600;
    }
}

.toast-success {
    background-color: green !important;
}

.toast-error {
    background-color: #ff0000 !important;
}

.setting-btn {
    > span {
        display: flex !important;
        align-items: center !important;
    }
}

.setting-btn:hover {
    border-color: rgb(0, 123, 255) !important;
    background: #fff !important;

    i,
    span {
        color: rgb(0, 123, 255) !important;
    }
}

//fieldset ptw
.ptw-fieldset {
    > legend {
        text-transform: uppercase;
        background: #f1a229;
        border-radius: 4px;
        height: 24px;
        line-height: 24px;
        color: #fff;
    }
}
.ptw-main-fieldset {
    > legend {
        text-transform: uppercase;
        background: #1b9fd6;
        border-radius: 4px;
        height: 24px;
        line-height: 24px;
        color: #fff;
    }
}

.ag-react-container {
    span.hover-tag-a {
        cursor: pointer;
        text-decoration-line: unset !important;
        color: rgb(0, 123, 255) !important;

        &:hover {
            text-decoration-line: underline !important;
            color: rgb(0, 123, 255) !important;
        }
    }
}

.style-fixed-header {
    position: sticky;
    top: 0px;
    padding: 5px;
    box-shadow: 0 4px 2px -2px rgba(55, 183, 255, 0.35);
    background: #ffffff;
    z-index: 11;
    display: flex;
    margin-bottom: 10px;

    .header-right {
        height: 28px;
        align-items: center;
        margin-top: 2px;
    }

    .header-left {
        display: flex;
        flex: 1;
        align-items: center;
        font-size: 13px;
        font-weight: 600;
        .title {
            color: #ff5722;
            margin-right: 30px;
            font-weight: 600;
            font-size: 14px;
        }

        .label {
            color: #000;
            text-transform: none;
            margin-right: 5px;
        }

        .status {
            color: rgb(144, 136, 0);
            border: 1px solid rgb(144, 136, 0);
            margin: 4px 5px;
            line-height: 20px;
            padding: 0px 12px;
            font-size: 11px;
            border-radius: 3px;
            float: right;
        }
    }
}

.body-wrap-checkboxlist {
    margin-top: 0.3rem;
}

form {
    .buttons {
        justify-content: center !important;
    }
}

.item-input-checkbox {
    > label {
        display: flex;
        margin-top: 1px;
    }
}

.rc-select-dropdown {
    z-index: 10;
}
.treeSelect {
    .ant-tree-select {
        .ant-select-clear {
            z-index: 1 !important;
        }
    }
}

.base-form-modal {
    .file-picker {
        div {
            .display-Grid {
                margin-top: 0px !important;
            }
            .inputFile-multi {
                margin-top: 0 !important;
                display: flex !important;
                padding: 2px 6px !important;
                > span {
                    font-size: 14px;
                }
                > div {
                    font-size: 12px;
                }
            }
        }
    }
}

.grid-action-bar-wrap {
    .ant-btn-custom {
        > span {
            font-size: 16px;
            line-height: 0 !important;
        }
    }
    .button-action-bar {
        border-radius: 4px;
        margin-left: 7.5px;
    }
}
