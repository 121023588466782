.style-update-booking {
    // @import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
    .render-top-label {
        padding-top: 0;
        padding-bottom: 8px;
        margin-bottom: 16px;
        .header-left {
            .code {
                color: #ff5722;
                font-weight: 600;
                margin-left: 5px;
                margin-right: 5px;
            }
            .status-booking {
                margin-left: 5px;
                width: unset;
                padding: 2px 5px;
            }
        }
    }
    .phone-input {
        width: 100%;
    }
    .loyalty-info {
        font-style: italic;
        color: var(--main-color-blue-light);
        text-align: right;
        padding-bottom: 10px;
    }
    .wrap-form {
        > form {
            margin: 0px 5px 0px 5px;
            .button-center {
                margin-top: 15px !important;
                margin-bottom: 10px !important;
            }
        }
    }

    .date-time-custom {
        .time-dropdown {
            left: unset !important;
            right: 0 !important;
        }
    }

    .grid-booking-service {
        .placeholder-text {
            font-size: 12px !important;
            width: calc(100% - 25px) !important;
        }
        .label-bottom {
            font-weight: 600;
        }
        .icon-remove {
            .anticon-close {
                > svg {
                    font-size: 10px !important;
                }
            }
        }
        .textbox {
            .text-input::placeholder {
                color: red !important;
            }
        }
        .error-technican {
            opacity: 1 !important;
            border-bottom: 1px solid red !important;
            margin-bottom: 1px !important;
        }

        .ag-body-viewport {
            max-height: 280px;
            overflow-y: auto;
            overflow-x: hidden;
        }
        .ag-center-cols-viewport {
            background: #fff;
        }
        .price {
            .placeholder {
                padding-left: 0px !important;
            }
            .wrap-con-field {
                > span:first-child {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 32px !important;
                }
            }
            .text-input {
                text-align: right !important;
                font-size: 12px !important;
                padding-bottom: 7px !important;
            }

            .icon-remove {
                width: 24px !important;
                margin-left: -10px !important;
                padding-top: 2px !important;
            }
        }
        .cpn-add-extra {
            height: 100%;
            margin-left: -11px;
            margin-right: -11px;
            width: unset !important;
            border-radius: 0px;
            border: 0px;
        }
        .id-grid-cell-renderer {
            height: 100%;
            margin-left: -11px;
            margin-right: -11px;
            border-radius: 0px;
            border: 0;
            width: unset !important;
            .drop-down-cpn {
                border: 0;
            }
            .rc-select-selector {
                border: 0;
            }
        }
        .ag-row-selected {
            .drop-down-list {
                background-color: #b7e4ff !important;
            }
        }
        .ag-row-even {
            &:hover {
                .drop-down-list {
                    background-color: #ecf0f1;
                }
            }
        }
        .ag-floating-bottom-viewport {
            .ag-row-odd {
                .drop-down-list {
                    background-color: #fff;
                }
            }
            .ag-row-even {
                &:hover {
                    .drop-down-list {
                        background-color: #fff;
                    }
                }
            }
        }
        .ag-row-odd {
            .drop-down-list {
                background-color: #f7fafb;
            }
        }
        .empty-text {
            position: unset;
            display: none !important;
        }
    }
}
.search-contact-overlay {
    z-index: 999999 !important;
    padding-top: 0px;
    .ant-popover-arrow {
        display: none;
    }
    .ant-popover-inner-content {
        box-shadow: 0 0 4px var(--main-input-border-color) !important;
        border-color: var(--main-input-border-color);
        max-height: 250px;
        overflow: auto;
        padding: 0px;
        .phone-row {
            padding: 5px 10px;
            display: flex;
            align-items: center;
            cursor: pointer;
            &:hover {
                background: #e6f7ff;
            }
        }
    }
}
