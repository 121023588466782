.customer-note {
    margin-bottom: 20px;
    margin-top: 10px;
    margin-left: 1px;
    .ant-timeline {
        .ant-timeline-item {
            padding-bottom: 15px;
        }
    }
    .ant-timeline-item-last > .ant-timeline-item-content {
        min-height: unset !important;
    }
    .btn-add-note {
        display: flex;
        width: 102px;
        border-radius: 3px;
        padding: 8px 10px;
        color: #fff;
        background: #1890ff;
        border-color: #1890ff;
        cursor: pointer;
        margin-top: 10px;
        .icon-add {
        }
        .label {
            margin-left: 5px;
            font-size: 14px;
        }
        &:hover {
            text-decoration: none;
            color: #fff;
            background: #339ef5;
            border-color: #339ef5;
        }
    }
}
.grid-customer-note {
    .switch-status {
        .toggle-switch {
            height: 100% !important;
        }
    }
    .input-customer-note {
        height: 100%;
        margin-left: -11px;
        margin-right: -11px;
        width: unset !important;
        border-radius: 0px;
        border: 0px;
    }
    .ag-cell {
        padding-top: 0 !important;
        padding-bottom: 1px !important;
    }
    .icon-confirm {
        .action-btn-edit {
            height: 23px;
            &:hover {
                border-color: #2dce89;
            }
        }
    }
}
.action-btn-info {
    background: var(--main-color);
}
.field-array-mail {
    width: 100%;
    .email-cutomer {
        display: flex;
        .email-input {
            flex: 1;
        }
        .custom-delete {
            height: 28px;
            margin-left: 10px;
        }
    }
}
