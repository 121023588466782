:root {
  // Core
  --form-row-field-display: "";
  --form-row-field-label-with: 100%;
  --form-row-field-input-with: 100%;
  --form-row-field-error-with: 100%;
  --form-label-field-color: #333;
  --form-label-field-weight: 600;

  --main-error-color: red;
  --main-error-font-size: 12px;
  --main-error-font-weight: 600;
  --main-error-line-height: 50;
  --main-required-color: red;
  --main-required-font-size: 13px;

  --main-font-family-custom: Helvetica, arial, verdana, sans-serif;
  --main-disable-color: #006096;
  --main-icon-font-weight: 400;
  --main-label-font-size: 13px;
  --main-label-font-color: #006096;
  --main-label-font-weight: 700;
  --main-input-font-size: 13px;
  --main-input-font-color: #333;
  --main-input-font-weight: 400;
  --main-input-line-height: 30px;
  --main-input-border-radius: 3px;
  --main-input-border-color: #006096;
  --main-icon-font-size: 13px;
  --main-icon-font-color: #333;
  --main-icon-font-color: #006096;
  --main-icon-font-weight: 400;
  --main-icon-line-height: 32px;
  --main-font-size: 13px;
  --main-color: #006096;
  --main-border-color: #dddddd;
  --main-input-border-color-default: #dddddd;

  --button-height: 32px;
  --button-line-height: 26px;

  --main-color-green: #508e33;
  --main-color-green-light: #669d41;
  --main-color-blue-sky: #e5f4ff;
  --main-color-blue-light: #2fa4e7;
  --main-color-blue-ocean: #026eaa;
  --main-color-red: #e04248;
  --main-color-orange: #faa633;
}
