$base-color: #0092d9;
$header-color: var(--main-color-blue-light);
.header-title {
    background: $header-color;
    padding: 0 20px;
    margin-bottom: 15px;

    .detail-title {
        line-height: 48px;
        font-size: 13px;
        color: #fff;
        text-transform: uppercase;
    }

    .create-by {
        line-height: 48px;
        float: left;
        width: 100%;
        padding-right: 4px;
        color: #fff;
        text-align: right;
        font-size: 11px;
        font-style: italic;
        padding-right: 5px;
    }
}

.detail-service {
    line-height: 26px;
    width: 130px;
    color: $header-color;
}

.list-booking {
    .x-listitem-inner-el {
        padding: 0;
    }

    .x-show-selection {
        > .x-listitem.x-selected {
            float: left;
            z-index: 100;
            position: relative;
            background: transparent;

            &::before {
                position: absolute;
                content: "";
                height: 100%;
                width: 100%;
                background: transparent;
                z-index: 99;
                border: 1px solid var(--main-color-blue-light);
            }
        }
    }
}

.booking-box {
    width: 100%;
    float: left;
    padding: 6px 8px;

    &.old {
        background: #f0f0f0;
    }

    .booking-title {
        font-size: 12px;
        line-height: 20px;
        float: left;
        color: var(--main-color-blue-light);
        padding: 4px 0;
        width: 100%;
    }

    .booking-description {
        .booking-info-row {
            font-size: 12px;
            line-height: 20px;
            float: left;
            padding: 2px 0;
            width: 100%;

            > i {
                font-size: 14px !important;
                width: 20px;
                text-align: center;
            }
        }
    }

    .booking-info {
        font-size: 12px;
        line-height: 20px;
        float: left;
        width: 100%;
        display: flex;

        .booking-info-colum {
            flex: 1;

            .booking-info-row {
                font-size: 12px;
                line-height: 20px;
                float: left;
                padding: 2px 0;
                width: 100%;

                > i {
                    font-size: 14px !important;
                    width: 20px;
                    text-align: center;
                }
            }

            &:last-child {
                width: 100px;
                flex: none;
            }
        }
    }
}

//choose technician
.label-choose-technician {
    width: 120px;
    line-height: 34px;
    text-align: left;
    font-size: 13px;
    color: #333;
}

.choose-technician {
    flex: 1;

    .x-inner-el {
        padding: 7px 5px 3px !important;
        line-height: 18px;
        border-bottom: 1px solid #ccc;

        .x-body-el {
            display: flex;

            .x-icon-el {
                margin-right: 0;
                margin-left: 5px;
                width: 24px;
                position: absolute;
                right: 0;
            }

            .x-text-el {
                flex: 1;
                color: #333;
            }
        }
    }

    .btn-clear-technician {
        cursor: pointer;
        position: absolute;
        right: 24px;
        top: 5px;
        z-index: 10;
    }
}

.grid-select-technician {
    .x-gridcell {
        border-bottom: 0;
    }
}

.background-hours {
    background: #e5e5e5 !important;
}

.background-line {
    background: #e5e5e5 !important;
}

.status {
    width: 80px;
    line-height: 26px; // background: #fff;
    // border: 2px solid #ff9800;
    // color: #ff9800;
    height: 28px;
    float: left;
    margin-left: 10px;
    text-align: center;

    &.Waiting {
        border: 2px solid #ff8c00;
        color: #ff8c00;
    }

    &.Checkedin {
        border: 2px solid #ff4a00;
        color: #ff4a00;
    }

    &.Processing {
        border: 2px solid #2196f3;
        color: #2196f3;
    }

    &.Completed {
        border: 2px solid #4caf50;
        color: #4caf50;
    }

    &.Cancel {
        border: 2px solid var(--main-color-red);
        color: var(--main-color-red);
    }
}

.schedule-status {
    font-weight: 600;

    &.Waiting {
        color: #ff8c00;
    }

    &.Checkedin {
        color: #ff4a00;
    }

    &.Processing {
        color: #2196f3;
    }

    &.Completed {
        color: #4caf50;
    }

    &.Cancel {
        color: var(--main-color-red);
    }
}

.status-column {
    text-align: center;
    padding: 2px 4px;
    width: 70px;
    display: block;

    &.Waiting {
        border: 1px solid #ff8c00;
        color: #ff8c00;
    }

    &.Checkedin {
        border: 1px solid #ff4a00;
        color: #ff4a00;
    }

    &.Processing {
        border: 1px solid #2196f3;
        color: #2196f3;
    }

    &.Completed {
        border: 1px solid #4caf50;
        color: #4caf50;
    }

    &.Cancel {
        border: 1px solid var(--main-color-red);
        color: var(--main-color-red);
    }
}

.search-booking {
    position: absolute;
    right: calc(60% + 10px);
    top: 14px;
    z-index: 100;
}

//Schedules technician

.header-schedules-mobile {
    .datepicker-override {
        .react-datepicker-wrapper {
            .react-datepicker__input-container {
                &::after {
                    bottom: 0px;
                    height: 1px;
                }

                height: 30px;

                input {
                    height: 30px;
                    padding: 0;
                    line-height: 29px;
                    border-radius: 0;
                }

                .react-datepicker__close-icon {
                    &::after {
                        top: 13px;
                    }
                }
            }
        }
    }
}

.header-schedules {
    margin-top: 10px;
    margin-bottom: 5px;
}

.content-schedules {
    .left-content-schedules {
        //old style
        margin-right: 5px;

        .title {
            float: left;
            padding-top: 15px;
            line-height: 25px;
            font-size: 13px;
            font-weight: 600;
            text-align: center;
            width: 80px;
        }

        .technicians {
            margin: 0;
            margin-top: 40px;
            padding: 0;
            list-style: none;

            .li-technician {
                padding: 4px 15px;
                height: 44px;
                font-size: 12px;
                font-weight: 600;
                line-height: 36px;
                border: 1px solid #e5e5e5;
                border-bottom-width: 0;

                &:last-child {
                    border-bottom-width: 1px;
                }
            }
        }

        .bookings {
            margin: 0;
            padding: 0;
            list-style: none;

            .li-bookings {
                padding: 4px 8px;
                height: 122px;
                text-align: center;
                font-size: 12px;
                font-weight: 600;
                line-height: 70px;
                position: relative;
                border: 1px solid #e5e5e5;
                border-bottom-width: 0;

                &:last-child {
                    border-bottom-width: 1px;
                }

                .total-booking {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 20px;
                    height: 20px;
                    padding-left: 5px;
                    line-height: 20px;
                    background: #5eb5e0;
                    color: #fff;

                    // &:before {
                    //   content: '';
                    //   display: block;
                    //   position: absolute;
                    //   left: 100%;
                    //   top: 0px;
                    //   width: 0;
                    //   height: 100%;
                    //   border-top: 20px solid transparent;
                    //   border-left: 10px solid #5eacd3;
                    //   border-bottom: 0px solid transparent;
                    //   border-right: 20px solid transparent
                    // }
                }

                .booking-in-time {
                    line-height: 44px;
                    position: absolute;
                    top: 70px;
                    left: 0;
                }
            }
        }
    }

    .left-content-schedules-booking {
        margin-right: 3px;

        > div::-webkit-scrollbar {
            display: none;
            overflow: hidden !important;
        }

        > div {
            //pointer-events: none;
            overflow: hidden !important;
        }

        .scroll-mark {
            overflow: scroll;
            visibility: hidden;
            -webkit-box-flex: none;
            -webkit-flex: none;
            flex: none;
        }

        .title {
            float: left;
            line-height: 40px;
            font-size: 13px;
            font-weight: 600;
            text-align: center;
            width: 77px;
            color: var(--main-color);
            background: #cdcdcd;
            border-left: 1px solid #cdcdcd;
            border-right: 1px solid #cdcdcd;
        }

        .technician-title {
            float: left;
            line-height: 40px;
            font-size: 13px;
            font-weight: 600;
            text-align: center;
            width: 77px;
            color: #333;
            background: #fff;
        }

        .bookings {
            margin: 0;
            padding: 0;
            width: 77px;
            list-style: none;

            .li-bookings {
                padding: 4px 8px;
                height: 122px;
                text-align: center;
                font-size: 12px;
                font-weight: 600;
                line-height: 70px;
                position: relative;
                border: 1px solid #e5e5e5;
                border-bottom-width: 0;
                .fa-plus-circle {
                    padding-right: 0 !important;
                    display: flex;
                    justify-content: center;
                }

                &:last-child {
                    border-bottom-width: 1px;
                }

                .total-booking {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 20px;
                    height: 20px;
                    padding-left: 5px;
                    line-height: 20px;
                    background: #5eb5e0;
                    color: #fff;

                    // &:before {
                    //   content: "";
                    //   display: block;
                    //   position: absolute;
                    //   left: 100%;
                    //   top: 0px;
                    //   width: 0;
                    //   height: 100%;
                    //   border-top: 20px solid transparent;
                    //   border-left: 10px solid #5eacd3;
                    //   border-bottom: 0px solid transparent;
                    //   border-right: 20px solid transparent;
                    // }
                }

                .booking-in-time {
                    line-height: 44px;
                    position: absolute;
                    top: 70px;
                    left: 0;

                    .btn-kiritm-create-booking {
                        width: 60px;
                        height: 32px;
                        background: var(--main-color-green);
                        border: 2px solid var(--main-color-green);
                        color: #fff;
                        cursor: pointer;

                        &.btn-disabled {
                            opacity: 0.5;

                            &:hover {
                                background: var(--main-color-green);
                                color: #fff;
                                cursor: not-allowed;
                                > svg {
                                    fill: #fff;
                                }
                            }
                        }

                        &:hover {
                            background: #fff;
                            color: var(--main-color-green);
                        }

                        i {
                            font-style: normal;
                            font-size: 16px;
                        }
                    }
                }
            }

            .li-bookings-with-note {
                height: 136px !important;
            }
        }

        .technicians {
            margin: 0;
            padding: 0;
            width: 150px;
            list-style: none;

            .li-technician {
                padding: 4px 15px;
                height: 44px;
                font-size: 12px;
                font-weight: 600;
                line-height: 36px;
                border: 1px solid #e5e5e5;
                border-bottom-width: 0;

                &.background-technician {
                    background: #ccefff;
                }
            }
        }
    }

    .right-content-schedules {
        //old style
        .header-time-schedule {
            list-style: none;
            margin: 0;
            padding: 0;
            height: 40px;
            width: 100%;
            float: left;

            .time-schedule {
                float: left;
                padding-top: 20px;
                line-height: 20px;
                font-size: 12px;
                font-weight: 600;
            }
        }

        .technicians-schedule {
            list-style: none;
            margin: 0;
            padding: 0;
            width: 100%;
            float: left;

            .li-technician-schedule {
                background: #f4f4f4;
                height: 44px;
                position: relative;
                border-top: 1px solid #e5e5e5;
                position: relative;

                &:nth-of-type(odd) {
                    background: #fdfdfd;
                }

                &:last-child {
                    border-bottom: 1px solid #e5e5e5;
                }

                .booking-time {
                    cursor: pointer;

                    .booking-tooltip {
                        white-space: nowrap;
                        font-size: 12px;
                    }
                }

                .time-schedule-row {
                    float: left;
                    width: 100%;

                    .time-schedule-col {
                        float: left;
                        border-left: 1px solid #e5e5e5;
                        height: 44px;

                        &:last-child {
                            border-right: 1px solid #e5e5e5;
                        }
                    }
                }

                .li-technician-schedule-after {
                    position: absolute;
                    z-index: 1;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    background-image: linear-gradient(90deg, #e5e5e5, #e5e5e5 1px, #f4f4f4 0, #f4f4f4 0, #e5e5e5 0, #e5e5e5 0, transparent 0, transparent);
                    background-position: 0 0;
                }
            }
        }
    }

    .right-content-header-schedules-booking {
        > div::-webkit-scrollbar {
            display: none;
            overflow: hidden !important;
        }

        .header-time-schedule {
            list-style: none;
            margin: 0;
            padding: 0;
            height: 40px;
            //width: 2640px;
            float: left;
            background: #cdcdcd;
            border-left: 1px solid #cdcdcd;
            border-right: 1px solid #cdcdcd;

            .time-schedule {
                float: left;
                line-height: 40px;
                font-size: 13px;
                font-weight: 600;
                color: #fff;
                text-align: center;

                .text-time {
                    width: 24px;
                    height: 24px;
                    background: var(--main-color-blue-light);
                    border-radius: 50%;
                    margin: 8px auto;
                    line-height: 25px;
                    display: block;
                }
            }
        }

        .header-time-technician-schedule {
            list-style: none;
            margin: 0;
            padding: 0;
            height: 40px;
            width: 1600px;
            float: left;
            background: #fff;

            .time-schedule {
                float: left;
                line-height: 40px;
                font-size: 13px;
                font-weight: 600;
                color: #333;
                text-align: center;
            }
        }
    }

    .right-content-schedules-booking {
        .schedules-booking {
            list-style: none;
            margin: 0;
            padding: 0;
            //width: 2640px;
            float: left;

            .li-schedules-booking {
                background: #f4f4f4;
                height: 122px !important;
                padding: 0;
                position: relative;
                border-top: 1px solid #e5e5e5;

                &:nth-of-type(odd) {
                    background: #fdfdfd;
                }

                &:last-child {
                    border-bottom: 1px solid #e5e5e5;
                }

                .booking-time {
                    cursor: pointer;

                    .booking-tooltip {
                        white-space: nowrap;
                        font-size: 12px;
                    }
                }

                .time-schedule-row {
                    float: left;
                    width: 100%;

                    .time-schedule-col {
                        float: left;
                        border-left: 1px solid #e5e5e5;
                        height: 122px;

                        &:last-child {
                            border-right: 1px solid #e5e5e5;
                        }
                    }
                }

                .li-schedules-booking-after {
                    position: absolute;
                    z-index: 1;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    background-image: linear-gradient(90deg, #e5e5e5, #e5e5e5 1px, #f4f4f4 0, #f4f4f4 0, #e5e5e5 0, #e5e5e5 0, transparent 0, transparent);
                    background-position: 0 0;
                }

                .list-booking-in-time {
                    width: 100%;
                    height: 100%;
                    float: left;
                    position: absolute;

                    .booking-in-time {
                        float: left;
                        //text-align: center;
                        height: 100%;
                        padding: 7px 0;
                        display: flex;
                        color: var(--main-color-blue-light);
                        font-size: 12px;
                        position: relative;

                        &.pointer {
                            cursor: pointer;
                        }

                        .left-booking-in-time {
                            flex: 1;
                        }

                        .action-in-time {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;

                            .btn-kiritm-create-booking {
                                margin: 0 auto;
                                width: 118px;
                                height: 32px;
                                background: var(--main-color-green);
                                border: 2px solid var(--main-color-green);
                                color: #fff;
                                cursor: pointer;

                                &.btn-disabled {
                                    opacity: 0.5;
                                    &:hover {
                                        cursor: not-allowed;

                                        background: var(--main-color-green);
                                        color: #fff;
                                        > svg {
                                            fill: #fff;
                                        }
                                    }
                                }

                                &:hover {
                                    background: #fff;
                                    color: var(--main-color-green);
                                }

                                i {
                                    font-style: normal;
                                    font-size: 16px;
                                }
                            }
                        }

                        .title-value {
                            float: left;
                            width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            max-width: 180px;
                            text-overflow: ellipsis;
                            line-height: 18px;
                            text-align: left;
                            padding-left: 10px;
                            color: #000;
                            font-size: 12px;

                            .highlight-value {
                                font-weight: 600;
                                color: var(--main-color-red);
                            }

                            .highlight-technician {
                                color: #7a25ea;
                                font-weight: 600;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                flex: 1;
                            }

                            .highlight-description {
                                font-weight: 600;
                                color: var(--main-color-red);
                                text-transform: uppercase;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                flex: 1;
                            }
                        }

                        .col-action {
                            display: flex;
                            width: 40px;
                            padding: 0 3px;
                            height: calc(100% - 14px);
                            align-items: center;

                            .list-action-booking {
                                .btn-kiritm-action {
                                    cursor: pointer;
                                    float: left;
                                    width: 34px;
                                    height: 34px;
                                    background: var(--main-color-blue-light);
                                    color: #fff;
                                    border: 1px solid var(--main-color-blue-light);
                                    margin-bottom: 15px;
                                    padding: 0;
                                    text-align: center;

                                    &:hover {
                                        background: #fff;
                                        color: var(--main-color-blue-light);
                                    }

                                    &.btn-kiritm-cancel {
                                        background: var(--main-color-red);
                                        color: #fff;
                                        border: 1px solid var(--main-color-red);

                                        &:hover {
                                            background: #fff;
                                            color: var(--main-color-red);
                                        }
                                    }

                                    &.btn-kiritm-complete {
                                        background: var(--main-color-green);
                                        color: #fff;
                                        border: 1px solid var(--main-color-green);

                                        &:hover {
                                            background: #fff;
                                            color: var(--main-color-green);
                                        }
                                    }
                                    i {
                                        font-style: normal;
                                        font-size: 16px;
                                    }
                                }
                            }
                        }
                    }

                    .waiting {
                        background-color: #fbf6ca;
                        border: 2px solid #f5ac53;
                        border-radius: 6px;
                    }

                    .processing {
                        background-color: #d1eeff;
                        border: 2px solid #2c8eb8;
                        border-radius: 6px;
                    }

                    .completed {
                        background-color: #f2ffe4;
                        border: 2px solid #73a839;
                        border-radius: 6px;
                    }

                    .cancel {
                        background: #ffefe4;
                        border: 2px solid #dd5600;
                        border-radius: 6px;
                    }
                }
            }

            .li-schedules-booking-with-note {
                height: 136px !important;
                .time-schedule-col {
                    height: 136px !important;
                }
            }
        }

        .technician-schedules-booking {
            list-style: none;
            margin: 0;
            padding: 0;
            width: 1600px;
            float: left;

            .li-technician-schedules-booking {
                background: #f4f4f4;
                height: 44px;
                padding: 0;
                position: relative;
                border-top: 1px solid #e5e5e5;

                &.background-technician {
                    background: #ccefff;
                }

                &:nth-of-type(odd) {
                    background: #fdfdfd;
                }

                &:last-child {
                    border-bottom: 1px solid #e5e5e5;
                }

                .technician-schedule-row {
                    float: left;
                    width: 100%;

                    .technician-schedule-col {
                        float: left;
                        border-left: 1px solid #e5e5e5;
                        height: 44px;

                        &:last-child {
                            border-right: 1px solid #e5e5e5;
                        }
                    }
                }
            }
        }
    }
}

.hidden-icon {
    width: 5px !important;
    margin: 0 !important;
}

.total-booking {
    margin-top: -1px;
    margin-left: -1px;
    float: left;
    width: calc(100% + 2px);
    display: flex;
    height: 34px;
    background: #fff;
    border: 1px solid #ddd;

    .total {
        flex: 1;
        padding-right: 15px;
        text-align: right;
        line-height: 33px;
        font-size: 12px;
        font-weight: 600;
        border-right: 1px solid #ddd;
    }

    .price {
        width: 120px;
        padding-left: 8px;
        text-align: left;
        line-height: 33px;
        font-size: 12px;
        font-weight: 600;
        border-right: 1px solid #ddd;

        &.price-update {
            width: 60px;
        }
    }

    .time {
        width: 120px;
        padding-left: 8px;
        text-align: left;
        line-height: 33px;
        font-weight: 600;
        font-size: 12px;

        &.time-update {
            width: 137px;
        }
    }

    .action {
        width: 117px;
        text-align: left;
        line-height: 33px;
        font-size: 12px;
    }

    .content-extra {
        flex: 1;

        .label-extra {
            float: left;
            line-height: 32px;
            text-align: right;
            padding-right: 8px;
            width: 120px;
            font-weight: 600;
        }

        .input-extra-service {
            height: 32px;
            line-height: 32px;
            padding-left: 8px;
            border: 1px solid #ccc !important;
            width: calc(100% - 120px);
            text-align: left;
        }
    }

    .price-extra {
        width: 175px;

        .label-extra {
            float: left;
            line-height: 32px;
            text-align: right;
            padding-right: 8px;
            width: 50px;
            font-weight: 600;
        }

        .input-extra-service {
            height: 32px;
            padding-left: 8px;
            line-height: 32px;
            border: 1px solid #ccc !important;
            text-align: left;
            width: 120px;
        }
    }
}

.list-booking-item-inner .x-innerhtml {
    flex: 1;
}

// combobox new-booking

.item-info-booking {
    padding: 7px 10px 0px;
    float: left;
    width: 100%;
    border-top: 1px dashed #ccc;

    cursor: pointer;

    .text {
        float: left;
        width: 100%;
        line-height: 22px;
        padding: 0;

        .text-code {
            font-weight: 600;
            color: var(--main-color-red);
            margin-right: 25px;
        }

        .text-phone {
            font-weight: 600;
            color: var(--main-color-blue-light);
        }

        .text-name {
            font-weight: 600;
            color: var(--main-color-blue-light);
        }

        .text-time {
            font-weight: 600;
            color: var(--main-color-red);
        }

        .text-status {
            font-weight: 600;
            color: #ff8c00;
        }
        .display-block {
            display: none;
        }
    }
}

.info-warning {
    padding: 15px 10px;
    font-size: 14px;
    color: #333;
    line-height: 22px;
}

// statistic

.statistic-of-day {
    position: relative;
    box-shadow: 0px 0px 10px #c1e4ff;
    margin-right: 0px;
    .button-statistic {
        cursor: pointer;
        height: 36px;
        line-height: 36px;
        width: 36px;
        font-size: 16px;
        text-align: center;
        background: #fff;
        border-radius: 3px;
        &:hover {
            background: var(--main-color-blue-ocean);
            color: #fff;
            border-radius: 3px;
        }
    }

    .popup-statistic {
        z-index: 1000;
        position: absolute;
        top: 45px;
        right: 5px;
        width: 280px;
        background: #fff;
        -webkit-box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.4);
        box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.4);

        .statistic-info {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                right: 8px;
                top: -5px;
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 5px solid black;
            }

            .title-statistic {
                float: left;
                width: 100%;
                line-height: 20px;
                padding: 10px 15px;
                background: #000000;
                color: #fff;
                font-size: 13px;
                font-weight: 600;
            }

            .content-statistic {
                float: left;
                width: 100%;
                padding: 10px 0;

                .row-info {
                    float: left;
                    width: 100%;
                    display: flex;
                    padding: 0 15px;
                    color: #333;

                    &:hover {
                        background: #e0e0e0;
                    }

                    .text-info {
                        flex: 1;
                        text-align: left;
                        line-height: 32px;
                        font-size: 13px;
                    }

                    .value-info {
                        width: 70px;
                        text-align: right;
                        line-height: 32px;
                        font-size: 13px;
                        font-weight: 600;
                        color: orangered;
                    }
                }

                .row-info-child {
                    float: left;
                    width: 100%;
                    display: flex;
                    padding: 0 15px 0 30px;
                    color: #333;

                    &:hover {
                        background: #e0e0e0;
                    }

                    .text-info {
                        flex: 1;
                        text-align: left;
                        line-height: 32px;
                        font-size: 13px;
                    }

                    .value-info {
                        width: 50px;
                        text-align: right;
                        line-height: 32px;
                        font-size: 13px;
                        font-weight: 600;
                        color: orangered;
                    }
                }
            }
        }
    }
}
.three-dot-dashboard {
    height: 36px;
    line-height: 36px;
    width: 36px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 0px 0px 10px #c1e4ff;
    &:hover {
        background: var(--main-color-blue-ocean);
        > svg {
            fill: #fff;
        }
    }
}
.btn-kiritm-deselect {
    height: 28px;
    width: 40px;
    background: var(--main-color-red);
    color: #fff;
    border: 0;
    padding: 0;
    text-align: center;

    > i {
        font-size: 16px;
    }
}

.btn-kiritm-deselect-extra {
    height: 28px;
    width: 40px;
    background: var(--main-color-red);
    color: #fff;
    border: 0;
    margin-right: 10px;
    padding: 0;
    text-align: center;

    > i {
        font-size: 16px;
    }
}

.btn-kiritm-extraservice {
    height: 30px;
    width: 40px;
    background: var(--main-color-green);
    color: #fff;
    border: 0;
    margin-right: 10px;
    padding: 0;
    text-align: center;

    > i {
        font-size: 16px;
    }
}

.btn-kiritm-add-service {
    height: 30px;
    width: 80px;
    background: var(--main-color-green);
    color: #fff;
    border: 0;
    margin-right: 10px;
    text-align: center;
    padding: 0;

    > i {
        font-size: 16px;
    }
}

.btn-kiritm-deselect-service {
    height: 30px;
    width: 90px;
    background: var(--main-color-red);
    color: #fff;
    border: 0;
    text-align: center;
    padding: 0;

    > i {
        padding: 0;
        margin: 0;
        font-size: 16px;
    }
}

.btn-kiritm-edit-customer {
    background: var(--main-color-blue-light);
    height: 30px;
    width: 30px;
    border: 0;
    padding: 0;
    text-align: center;

    > i {
        font-style: normal;
        color: #fff;
        font-size: 16px;
        text-align: center;
        line-height: 30px;
    }
}

.x-big {
    .tree-service-mobile {
        .btn-action-service-select {
            height: 26px;
            width: 80px;
            background: var(--main-color-green);
            color: #fff;
            border: 0;
            font-size: 11px;
            text-transform: uppercase;
        }

        .x-gridcell-body-el {
            padding: 6px 8px;
        }

        .x-treecell-body-el {
            padding: 6px 0;
        }
    }

    .booking-mobile {
        .x-trigger {
            .x-icon-el {
                &:before {
                    font-size: 20px !important;
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .extra-services-mobile {
            margin-top: 10px;

            .comboxkiritm {
                margin-bottom: 0;
                padding-bottom: 0;
            }

            .mobile-input {
                > .x-body-wrap-el {
                    > .x-body-el {
                        height: 30px;
                    }
                }
            }
        }

        .mobile-input {
            padding-bottom: 0 !important;

            .x-label-el {
                height: 40px;
            }

            > .x-body-wrap-el {
                > .x-body-el {
                    height: 30px;
                }
            }
        }

        //form info
        .datepicker-override {
            .react-datepicker-wrapper {
                .react-datepicker__input-container {
                    &::after {
                        bottom: 0px;
                        height: 1px;
                    }

                    height: 30px;

                    input {
                        height: 30px;
                        padding: 0;
                        line-height: 29px;
                        border-radius: 0;
                    }

                    .react-datepicker__close-icon {
                        &::after {
                            top: 13px;
                        }
                    }
                }
            }
        }
    }
}

.rdg-editor-container {
    > select {
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

        > option {
            line-height: 26px;
            height: 26px;
        }
    }
}

.virtualized-select-container {
    .react-select-kiritmz {
        height: 36px;
        min-height: 36px;

        .kiritmz__control {
            height: 36px;
            min-height: 36px;
        }

        .kiritmz__menu {
            max-height: 400px !important;
            overflow-y: auto !important;

            .option-kiritmz {
                line-height: 96px;
                padding: 0;
                height: 96px;
            }
        }
    }
}

.filter-date {
    .btn-date-filer {
        height: 34px;
        padding: 0 8px;
        border-radius: 3px;
        min-width: 34px;
        text-align: center;
        border: 1px solid #ccc;
        color: #333;
        background: #fff;
        margin-top: 1px;
        cursor: pointer;

        &:hover {
            color: var(--main-color-blue-light);
            border-color: var(--main-color-blue-light);
        }
    }
}

// paymentMethod
.list-payment-method {
    width: 100%;

    .method {
        width: 100%;
        display: flex;
        padding: 8px 15px;
        line-height: 32px;
        border-bottom: 1px dashed #ccc;
        cursor: pointer;

        &:hover {
            background: #d5f9ca;
        }

        &:last-child {
            border-bottom: 1px dashed transparent;
        }

        .title {
            flex: 1;
            font-size: 14px;
            font-weight: 600;
            color: #333;
        }

        .fee {
            font-size: 13px;
            font-weight: 600;
            padding: 0 15px;
            color: var(--main-color-red);
        }

        .select-method {
            width: 40px;
            height: 32px;
            background: #fff;
            border: 2px solid #4caf50;
            text-align: center;

            > i {
                font-size: 16px;
                line-height: 30px;
                color: #4caf50;
            }
        }
    }
}
