.color-picker-custom {
    @import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";

    /* DropDownButton preview customization */
    #dropdownbtn .e-btn-icon.e-dropdownbtn-preview {
        // background-color: #008000;
        height: 18px;
        width: 18px;
        margin-top: 0;
    }

    #dropdownbtn {
        padding: 4px;
    }
    .e-icon-right {
        margin-top: 2px !important;
    }
    .e-split-colorpicker {
        pointer-events: none !important;
        z-index: 0 !important;
    }
}
.e-color-palette {
    width: 300px !important;
}
.e-palette {
    padding: 10px;
}
.e-container .e-palette .e-circle-palette {
    border: 0;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    margin: 3px;
}
.e-container .e-palette .e-circle-palette {
    border: 0;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    margin: 3px;
}
.e-bigger .e-container .e-palette .e-circle-palette {
    height: 22px;
    width: 22px;
}
.e-container .e-palette .e-circle-palette:hover {
    box-shadow: none;
    transform: scale(1.2);
    transition: transform 0.2s ease-out;
}
.e-circle-palette .e-circle-selection {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    display: inline-block;
    transform: scale(0);
    transition: transform 1.2s ease-in;
}
.e-circle-palette.e-selected .e-circle-selection {
    transform: scale(0.8);
    background-color: #fff;
    transition: transform 0.2s ease-out;
}
#circle-palette + .e-container,
#scroll-palette + .e-container {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
}
#scroll-palette + .e-container .e-custom-palette.e-palette-group,
.e-bigger #scroll-palette + .e-container .e-custom-palette.e-palette-group {
    height: 125px;
}
.e-container .e-palette .e-scroll-palette {
    border: 0;
    color: #fff;
    line-height: 24px;
    font-size: 16px;
    height: 24px;
    width: 35px;
    margin-top: 2px;
    margin-left: 10px;
}
.e-bigger .e-container .e-palette .e-scroll-palette {
    height: 24px;
    width: 35px;
}
.e-container .e-palette .e-scroll-palette.e-selected::before {
    content: "\e933";
}
.highcontrast .e-container .e-palette {
    border-bottom-color: transparent;
}
.e-switch-ctrl-btn {
    padding: 10px 16px 16px !important;
}
.e-selected {
    outline: 0 !important;
}
