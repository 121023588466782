.dashboard-premium {
    @import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
    @import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
    @import "../../../../node_modules/@syncfusion/ej2-calendars/styles/material.css";
    @import "../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
    @import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
    @import "../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
    @import "../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
    @import "../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
    @import "../../../../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";
    .main-multi-none {
        .e-dock {
            display: none !important;
        }
        .e-appointment-wrapper {
            .e-appointment {
                max-width: calc(100% - 5.8px) !important;
                left: 3px !important;
            }
        }
    }
    .main-dashboard {
        margin-top: 15px;
    }
    .schedule-control-section {
        position: relative;
        .control-section {
            padding: 0 !important;
            .e-schedule {
                border: 0;
                box-shadow: 0px 0px 10px #c1e4ff;
                border-radius: 3px;
                .e-hide-childs > * {
                    display: block !important;
                    .hour-format {
                        display: none !important;
                    }
                    .count {
                        bottom: -19px;
                    }
                }
            }
            .e-header-row {
                .e-resource-cells {
                    border-left: 0px;
                    border-bottom: 0;
                    font-weight: 600;
                    font-size: 14px;
                    text-align: center;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
                    height: 41px;
                    padding: 4px 8px;

                    .male-sex {
                        color: #006096;
                        position: relative;
                        font-weight: 600;
                        text-transform: uppercase;
                        font-size: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 28px;
                    }
                    .female-sex {
                        color: #f48fb1;
                        position: relative;
                        font-weight: 600;
                        text-transform: uppercase;
                        font-size: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 28px;
                    }
                    .any-sex {
                        position: relative;
                        font-weight: 600;
                        text-transform: uppercase;
                        font-size: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 28px;
                    }
                    .tech-count-booking {
                        position: absolute;
                        right: -8px;
                        bottom: -6px;
                        background: #2fa4e7;
                        color: #fff;
                        font-weight: 400;
                        line-height: 14px;
                        font-size: 11px;
                        padding: 0px 3px;
                    }
                }
            }
            .e-all-day-row {
                display: none !important;
            }
            .e-date-header-wrap {
                border: 0 !important;
            }
            .multiSetting {
                .e-appointment-wrapper {
                    .e-appointment {
                        max-width: 90% !important;
                        left: 0;
                    }
                }
            }

            .e-appointment-border {
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
            }
            // .link-visible {
            //     .e-icon {
            //         .link {
            //             display: flex;
            //             align-items: center;
            //             justify-content: center;
            //             .number-link {
            //                 font-size: 11px;
            //                 padding-top: 1px;
            //                 color: var(--main-color-red);
            //                 margin-right: -2px;
            //             }
            //             .anticon-link {
            //                 padding: 1px 4px;
            //             }
            //         }
            //     }
            // }
            .e-appointment:focus {
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
            }

            .custom-major-lmt {
                position: relative;
                .count {
                    position: absolute;
                    right: -1px;
                    bottom: -9px;
                    background: #2fa4e7;
                    color: #fff;
                    height: 16px;
                    line-height: 12px;
                    font-size: 11px;
                    padding: 0px 3px;
                    padding-top: 2px;
                }
                .hour-format {
                    font-weight: 400;
                    font-size: 12px;
                    padding-right: 2px;
                }
            }
        }
    }
    .display-none {
        display: none !important;
    }
    .content-header::after {
        content: "Time/Techn";
        color: #006096 !important;
        font-weight: 600;
        font-size: 11px;
    }
    .content-header {
        border-right: 0px !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
        height: 41px !important;
    }
    .e-schedule .e-vertical-view .e-day-wrapper {
        .Waiting {
            background-color: #fbf6ca;
            border: 1px solid #f5ac53;
            color: #000;
        }

        .Processing {
            background-color: #d1eeff;
            border: 1px solid #2c8eb8;
            color: #000;
        }

        .Completed {
            background-color: #f2ffe4;
            border: 1px solid #73a839;
            color: #000;
        }

        .Cancel {
            background: #ffefe4;
            border: 1px solid #eee;
            color: #000;
        }
    }
}

.e-schedule .e-vertical-view .e-time-cells-wrap table td,
.e-schedule .e-vertical-view .e-work-cells {
    font-weight: 600;
    height: 40px;
    color: #333;
}

.e-schedule .e-vertical-view .e-date-header-wrap table col,
.e-schedule .e-vertical-view .e-content-wrap table col {
    width: 150px;
}
.multiSetting {
    .e-schedule .e-vertical-view .e-date-header-wrap table col,
    .e-schedule .e-vertical-view .e-content-wrap table col {
        width: 180px;
    }
}
.e-header-cells.e-disable-dates {
    display: none !important;
}

.e-schedule .e-vertical-view .e-left-indent .e-all-day-cells {
    display: none !important;
}
.e-schedule .e-spinner-pane {
    display: none !important;
}
.e-schedule .e-vertical-view .e-work-cells:not(.e-work-hours) {
    background: #eee;
    color: #fff;
    cursor: not-allowed;
}

.e-header-icon-wrapper {
    .e-edit {
        display: none !important;
    }
}
.e-timeline-wrapper {
    .e-current-timeline {
        z-index: 0 !important;
    }
}
.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-subject-wrap .e-subject {
    color: #000;
}
.drag-sample-wrapper {
    display: -ms-flexbox;
    display: flex;
}

.template-wrap-lmt {
    padding-left: 2px;
    .e-customer {
        font-size: 12px;
        white-space: normal;
        word-wrap: break-word;
        line-height: 18px;
        font-weight: 600;
        opacity: 0.85;
    }

    .e-phone {
        font-weight: 400;
    }
    .e-subject {
        display: flex;
        // padding-top: 3px !important;
        font-size: 11px !important;
        line-height: 18px;
        opacity: 0.9;
        padding-bottom: 2px;
        color: #333 !important;
    }
    .e-time {
        font-size: 11px !important;
        color: #333 !important;
        font-size: 10px !important;
        margin-top: -2px !important;
        opacity: 0.7;
        line-height: 18px;
    }
    .e-location {
        display: none !important;
    }
    .e-icon {
        bottom: 0px;
        right: 0px;
        position: absolute;
        display: flex;
        align-items: center;

        .link {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 15px;
            .number-link {
                font-size: 11px;
                padding-top: 1px;
                // color: var(--main-color-red);
                margin-right: -2px;
            }
            .anticon-link {
                padding: 1px 4px;
            }
        }
        > span {
            padding: 1px 3px;
        }
    }
}
.e-contextmenu {
    padding: 5px 0px !important;
    .e-menu-item {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 12px;
        height: 32px !important;
        .e-menu-icon {
            font-size: 12px !important;
            padding-top: 2px;
        }
        .new::before {
            content: "\e7f9";
            color: var(--main-color-blue-ocean);
        }
        .edit::before {
            content: "\ea9a";
        }
        .today::before {
            content: "\e322";
        }
        .delete::before {
            content: "\e94a";
            color: var(--main-color-red);
        }
        .cancel::before {
            font: normal normal normal 16px/1 FontAwesome;
            content: "\f05c";
            color: var(--main-color-red);
        }
        .noshow::before {
            font: normal normal normal 16px/1 FontAwesome;
            content: "\f28d";
            color: #ccc;
        }
        .clipboard::before {
            font: normal normal normal 16px/1 FontAwesome;
            content: "\f190";
            color: var(--main-color-blue-light);
        }

        .payment {
            margin-right: 13px !important;
        }
        .payment::before {
            font: normal normal normal 16px/1 FontAwesome;
            content: "\f0d6";
            color: var(--main-color-blue-light);
        }
        .update::before {
            font: normal normal normal 16px/1 FontAwesome;
            content: "\f044";
            color: #faa633;
        }
        .confirm::before {
            font: normal normal normal 16px/1 FontAwesome;
            content: "\f046";
            color: #00a98f;
        }
        .resend::before {
            font: normal normal normal 16px/1 FontAwesome;
            content: "\f0f3";
            color: #e9a5d4;
        }
        .detail::before {
            font: normal normal normal 16px/1 FontAwesome;
            content: "\f05a";
            color: var(--main-color-blue-light);
        }
        .complete::before {
            font: normal normal normal 16px/1 FontAwesome;
            content: "\f14a";
            color: var(--main-color-green);
        }
        .start::before {
            font: normal normal normal 16px/1 FontAwesome;
            content: "\f017";
            color: var(--main-color-blue-light);
        }
        .print::before {
            font: normal normal normal 16px/1 FontAwesome;
            content: "\f02f";
            color: var(--main-color-blue-light);
        }
        .print-slip::before {
            font: normal normal normal 16px/1 FontAwesome;
            content: "\f0ea";
            color: var(--main-color-blue-light);
        }
    }
}
.ant-collapse-header {
    .ant-collapse-arrow {
        position: absolute;
        top: 5px !important;
    }
}
