.main-drawer {
    font-size: var(--main-font-size);
    top: 56px !important;
    z-index: 0 !important;
    .ant-drawer-content-wrapper {
        -webkit-box-shadow: none;
        box-shadow: none;
        max-width: 450px;
        min-width: 300px;
    }

    .ant-drawer-body {
        padding: 0px;
        font-size: var(--main-font-size);
        position: relative;
    }
}
.drawer-pos-actions {
    font-size: var(--main-font-size);
    top: 0px !important;
    z-index: 2 !important;
    .ant-drawer-body {
        overflow: hidden;
    }
    .ant-drawer-content-wrapper {
        -webkit-box-shadow: none;
        box-shadow: none;
        max-width: 1000px;
        min-width: 300px;
    }

    .ant-drawer-body {
        padding: 0px;
        font-size: var(--main-font-size);
        position: relative;
    }
}
