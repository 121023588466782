.proceed-payment-wrap {
    padding: 10px;
    .label-field-text {
        font-weight: 400 !important;
    }
    .buttons {
        margin-top: 20px !important;
    }
    .note {
        color: red;
        font-style: italic;
        padding-left: 10px;
    }
    .proceed-payment {
        display: flex;
        .container-left {
            width: 195px;
            border-right: 1px solid #9ea2a5;
            .info-top {
                font-style: italic;
                font-weight: normal;
                line-height: 19px;
                font-weight: 600;
            }
            .info-bottom {
                margin-top: 15px;
                .title {
                    margin-bottom: 5px;
                    font-size: 14px;
                    font-weight: 600;
                }
                .title-value {
                    float: left;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    max-width: 180px;
                    text-overflow: ellipsis;
                    line-height: 22px;
                    text-align: left;
                    padding-left: 10px;
                    color: #000;
                    font-size: 12px;
                    display: flex;
                    .title-in-db {
                        width: 60px;
                    }
                    .highlight-value {
                        font-weight: 600;
                        color: var(--main-color-red);
                    }

                    .highlight-technician {
                        color: #7a25ea;
                        font-weight: 600;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        flex: 1;
                    }

                    .highlight-description {
                        font-weight: 600;
                        color: var(--main-color-red);
                        text-transform: uppercase;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        flex: 1;
                    }
                }
                .title-value-services {
                    white-space: unset;
                    text-overflow: unset;
                }
            }
        }
        .container-right {
            flex: 1;
            margin-left: 40px;
            .collapse-cpn {
                min-height: 30px;
            }
            .sub-total {
                display: flex;
                margin-top: 7px;
                font-size: 13px;
                .icon-info {
                    margin-left: 5px;
                    color: #026eaa;
                    font-size: 14px;
                    &:hover {
                        cursor: pointer;
                    }
                }
                .title-final {
                    width: 120px;
                    font-weight: 400;
                    text-align: left;
                    // margin-right: 25px;
                    margin-right: 10px;
                    font-size: 13px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; /* number of lines to show */
                    -webkit-box-orient: vertical;
                }
                .value-final {
                    font-weight: 600;
                    flex: 1;
                    // min-width: 45px;
                    // max-width: 65px;
                    text-align: left;
                    // margin-right: 300px;
                }
            }
            .last-sub-total {
                // border-bottom: 1px dashed #a8aeb3;
                // padding-bottom: 10px;
            }
            .total-in-cash {
                border-bottom: 1px dashed #a8aeb3;
                padding-bottom: 10px;
                margin-top: 0 !important;
            }
            .ant-collapse-header {
                padding: 0 !important;
                .ant-collapse-arrow {
                    padding: 0 !important;
                }
            }
            .ant-collapse-content-box {
                padding: 0 !important;
            }
            .gift-input {
                padding-top: 10px;
                padding-left: 10px;
                .row-voucher {
                    margin-top: 10px;
                }
                .row-gift {
                    margin-top: -10px;
                    margin-bottom: 10px;
                    .sub-total {
                        margin-top: 0px;
                    }
                }
                .error-field {
                    display: none !important;
                }
                .label-field {
                    // margin-right: 20px !important;
                    padding-right: 5px;
                }
                .voucher-code {
                    display: flex;
                    align-items: center;
                    > span:first-child {
                        flex: 1;
                        display: flex;
                    }
                    .wrap-con-field {
                        > span:first-child {
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                    .text-input {
                        padding-left: 10px !important;
                        flex: 1;
                    }
                    .button {
                        margin-left: 5px;
                        font-weight: 500;
                        color: rgb(255, 255, 255);
                        border: none;
                        outline: 0px;
                        background-color: #026eaa;
                        border-radius: 3px;
                        opacity: 1;
                        display: flex;
                        align-items: center;
                        width: 80px;
                        height: 30px;
                        display: inline-flex;
                        -webkit-box-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        justify-content: center;
                        cursor: pointer;
                        vertical-align: top;
                        &:hover {
                            background-color: var(--main-color);
                        }
                    }
                    .readOnly {
                        opacity: 0.5;
                        &:hover {
                            cursor: not-allowed !important;
                        }
                    }
                }
            }
            .total {
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                .icon-gift {
                    font-size: 16px;
                    color: #026eaa;
                    margin-left: -22px;
                    margin-right: 1px;
                }

                > svg {
                    margin-left: -23px;
                    margin-bottom: -3px;
                }
                .title {
                    font-size: 14px;
                    font-weight: 600;
                    padding-left: 5px;
                }
                .money {
                    margin-left: 25px;
                    font-weight: 600;
                    font-size: 18px;
                    color: #ff3232;
                }
            }
            .final-caculate {
                // margin-left: 5px;
            }
            .children {
                padding-left: 10px;
                .payment-method {
                    margin-bottom: 15px;

                    .title {
                        margin-bottom: 5px;
                        display: none;
                        // font-weight: 600;
                    }
                    .methods {
                        display: flex;
                        flex-wrap: wrap;
                        margin-right: -20px;

                        > div:first-child {
                            flex: 20%;
                            max-width: 82px;
                        }
                        .actived {
                            background: #026eaa !important;
                            color: #fff !important;
                        }
                        .item {
                            cursor: pointer;
                            flex: 20%;
                            height: 90px;
                            padding: 5px 2px;
                            background: #e5f4ff;
                            border-radius: 3px;
                            margin-right: 3px;
                            margin-top: 2px;
                            width: 82px;
                            max-width: 82px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            color: #026eaa;
                            font-size: 12px;
                            font-weight: 600;
                            text-transform: uppercase;
                            border: solid 1px transparent;
                            .item-title {
                                text-align: center;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2; /* number of lines to show */
                                -webkit-box-orient: vertical;
                            }
                            &:hover {
                                border: solid 1px var(--main-color);
                                box-shadow: 0 0 2px var(--main-input-border-color) !important;
                            }
                        }
                    }
                }
                .final-caculate {
                    margin-bottom: 7px;
                    margin-left: 0px;
                }
            }
            .discount-loyalty {
                // padding-bottom: 10px;
                // border-bottom: 1px dashed #a8aeb3;
                // margin-left: 5px;
                padding-left: 0px;
                margin-top: 5px;
            }
            .money-point {
                .point-info {
                    font-style: italic;
                    color: var(--main-color-blue-light);
                    text-align: right;
                }
            }
            .text-field {
                margin-left: 0px;
                .error-field {
                    padding-top: 0 !important;
                    font-style: italic;
                    font-weight: 400;
                }
                .textbox {
                    .text-input {
                        font-weight: 600;
                        color: #427623;
                    }
                }
                .receive-money-field {
                    padding-top: 10px;
                    .label-field {
                        // margin-right: 20px !important;
                    }
                    .input-field {
                        position: relative;
                    }
                }
                .back-money {
                    .label-field {
                        // margin-right: 20px !important;
                    }
                    .input-field {
                        text-align: left;
                        color: #427623;
                    }
                }
            }
            .voucher-field {
                margin-bottom: 10px;
            }
            .gift-card-field {
                .voucher-code {
                    .read-only {
                        pointer-events: all !important;
                    }
                    .search-gift {
                        display: flex;
                        width: 100%;
                    }
                }
                .gift-input {
                    .error-field {
                        display: inherit !important;
                    }
                }
            }
        }
    }
}
.gift-card-overlay {
    width: 286px !important;
    .ant-popover-inner {
        .ant-popover-inner-content {
            padding: 0px 10px 10px 10px !important;
            .section-content {
                max-height: 210px;
                overflow: auto;
            }
            .section-voucher-customer {
            }
            .section-other {
            }
        }
    }

    .idtek-grid-loading {
        right: 0px !important;
        bottom: 0px !important;
        left: 0px !important;
    }
    .group-header {
        padding: 5px;
        padding-left: 0px;
        line-height: 30px;
        font-weight: 600;
        opacity: 0.8;
    }
    .order-title {
        margin-top: -5px;
    }
    .empty-data {
        height: 100px;
        padding-top: 5px;
        .ant-empty-image {
            height: 60px;
        }
    }
    .voucher-card {
        &:hover {
            background: #e7f6ff;
        }
        margin-bottom: 5px;
        border: 1px dashed #a8aeb3 !important;
    }
    .disable-card {
        margin: 0px !important;
        padding-top: 10px;
        .voucher-card {
            background: #e7f6ff;
        }
    }

    .selected-card {
        background: #e7f6ff;
    }
    .button-apply {
        margin-left: 5px;
        font-weight: 500;
        color: rgb(255, 255, 255);
        border: none;
        outline: 0px;
        background-color: #026eaa;
        border-radius: 3px;
        opacity: 1;
        display: flex;
        align-items: center;
        width: 80px;
        height: 30px;
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        cursor: pointer;
        vertical-align: top;
        &:hover {
            background-color: var(--main-color);
        }
    }
    .readOnly {
        opacity: 0.5;
        &:hover {
            cursor: not-allowed !important;
        }
    }
}
.voucher-overlay {
    padding: 0 !important;
    width: 287px;
    margin-left: 2px;

    .ant-popover-inner-content {
        padding: 0 !important;
    }
    .ant-popover-inner {
        box-shadow: 0 0 4px var(--main-input-border-color) !important;
    }
    .voucher-card {
        display: flex;
        flex-direction: column;
        padding: 10px;
        height: 120px;

        .value {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            letter-spacing: 0px;
            margin: 0px;
            padding: 0px;
            font-size: 17px;
            font-weight: 500;
            line-height: 24px;
            max-height: 24px;
            color: rgb(36, 36, 36);
        }
        .condition {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            letter-spacing: 0px;
            margin: 0px;
            padding: 0px;
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            max-height: 20px;
            color: rgb(120, 120, 120);
        }
        .bottom {
            margin-top: auto;
            display: flex;
            align-items: flex-end;
            .end-date {
                flex: 1;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                letter-spacing: 0px;
                margin: 0px;
                padding: 0px;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                max-height: 20px;
                color: rgb(120, 120, 120);
            }
            .deselect {
                margin-left: 5px;
                font-weight: 500;
                color: rgb(255, 255, 255);
                border: none;
                outline: 0px;
                background-color: var(--main-color-red);
                border-radius: 3px;
                opacity: 1;
                display: flex;
                height: 30px;
                align-items: center;
                width: 80px;
                display: inline-flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                cursor: pointer;
                vertical-align: top;
                &:hover {
                    background-color: #cc3237;
                }
            }
        }
    }
    .active {
        border: 1px solid var(--main-input-border-color) !important;
    }
}
