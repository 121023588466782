.body-full-screen {
    height: calc(100% - 1px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .btn-full-screen .ant-btn > i {
        margin-top: -4px;
    }

    .anticon-fullscreen,
    .anticon-fullscreen-exit {
        font-size: 18px;
        margin-right: 0px !important;
    }

    .btn-full-screen {
        .ant-btn-custom {
            background-color: #ffffff;
            color: #1daee6;
        }

        &:hover {
            .ant-btn-custom {
                background-color: #1daee6;
                color: #ffffff;
            }
        }
    }
}

.button-modal-fullscreen {
    // color: rgb(242, 228, 228) !important;
    font-size: 13px;
    margin-top: 0px;
    color: var(--main-color-blue-ocean) !important;
}

.btn-download {
    color: #fff;
    background-color: #ff9233;
    border-color: #ff9233;

    &:hover {
        color: #fff;
        background: #bd6417;
    }
}

.btn-process {
    color: #fff;
    background-color: #9a9a9a;
    border-color: #9a9a9a;

    &:hover {
        color: #fff;
        background: #312727;
    }
}

.css-15k3avv {
    z-index: 2 !important;
}

.top-detail {
    display: flex;

    .btn-download {
        color: #2e7d32;
        background-color: #fff;
        border-color: #2e7d32;
        display: flex;
        padding: 3px 8px;
        color: #2e7d32;
        font-size: 12px;
        line-height: 22px;

        > i {
            margin: 4px 6px 4px 0;
            font-size: 16px;
            color: #2e7d32;
        }

        &:hover {
            color: #fff;
            background: #2e7d32;

            > i {
                background-color: #2e7d32;
                color: #fff;
            }
        }
    }
    .btn-download-word {
        color: #096dd9;
        background-color: #fff;
        border-color: #096dd9;
        display: flex;
        padding: 3px 8px;
        color: #096dd9;
        font-size: 12px;
        line-height: 22px;

        > i {
            margin: 4px 6px 4px 0;
            font-size: 16px;
            color: #096dd9;
        }

        &:hover {
            color: #fff;
            background: #096dd9;

            > i {
                background-color: #096dd9;
                color: #fff;
            }
        }
    }
}
