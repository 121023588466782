.grid-detail-booking {
    .ag-body-viewport {
        height: auto;
        max-height: 400px;
        overflow: auto;
    }
    .name-field {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
