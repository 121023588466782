.wrap-custom-date {
    &:hover {
        border: 1px solid var(--main-input-border-color) !important;
        box-shadow: none !important;
    }
    .react-datepicker-wrapper {
        flex: 1;
        .react-datepicker__input-container {
            .date-picker-custom::placeholder {
                opacity: 0.5;
            }
        }
    }
    .date-picker-custom {
        width: 100%;
        border: 0 !important;
        padding: 2px 10px;
        height: 28px !important;
        border-radius: 3px !important;
        background: #fff !important;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        &:focus {
            outline: 0 !important;
        }
    }
    .react-datepicker__close-icon::after {
        color: rgba(255, 0, 0, 0.7) !important;
        background: #fff !important;
        font-size: 23px;
        line-height: unset !important;
        height: 100%;
        display: flex;
        align-items: center;
        padding-bottom: 4px;
    }
    .react-datepicker-popper {
        margin-top: 5px !important;

        .react-datepicker {
            margin-left: 2px;
            border-radius: 3px !important;
            box-shadow: 0 0 4px var(--main-input-border-color) !important;
            // border-color: var(--main-input-border-color) !important;
            border: 0 !important;
            .react-datepicker__triangle {
                display: none;
            }
            .react-datepicker__day,
            .react-datepicker__time-list-item,
            .react-datepicker__navigation {
                &:focus {
                    outline: 0 !important;
                }
            }
            .react-datepicker__navigation--previous {
                border-right-color: var(--main-color) !important;
            }
            .react-datepicker__navigation--next {
                border-left-color: var(--main-color) !important;
            }
            .react-datepicker__header {
                padding-top: 5px !important;
                border-bottom: 1px solid #9ab7d4 !important;
                border-top-left-radius: 0 !important;
                background: #e6f7ff;
                .react-datepicker__current-month {
                    line-height: 24px !important ;
                    color: var(--main-color) !important;
                }
                .react-datepicker__day-name {
                    color: var(--main-color) !important;
                }
            }
            .react-datepicker__day {
                border-radius: 3px !important;
            }
            .react-datepicker__day--outside-month {
                cursor: default;
                color: #ccc;
                opacity: 0.8;
                pointer-events: none;
            }
            .react-datepicker__today-button {
                background: #fff;
                color: var(--main-color) !important;
                line-height: 24px !important ;
                border-top: 1px solid #9ab7d4 !important;
            }
        }
    }
    .react-datepicker__time-container--with-today-button {
        right: -85px !important;
    }
    .react-datepicker__time-container {
        border-radius: 3px !important;
        box-shadow: 0 0 4px var(--main-input-border-color) !important;
        border: 0 !important;
    }
    .react-datepicker__time-list {
        height: 237px !important;
    }
    .react-datepicker-time__header {
        color: var(--main-color) !important;
    }
}
.readOnly {
    .react-datepicker__close-icon {
        display: none !important;
    }
}
