.grid-container {
  padding: 15px;
}

// .row-field {
//  z-index: 10;
// }

.popup-crud-config-form {
  display: table;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  //.row-field {
  //  margin: 0px 5px 10px 5px;
  //}

  fieldset legend {
    font-size: 13px !important;
    font-weight: 600;
  }

  .rc-dialog-content {
    color: #333;
    background: transparent;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    height: 600px;

    .rc-dialog-header {
      border-bottom: 2px solid #c1e4ff !important;

      padding: 0px !important;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      .rc-dialog-title {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        background: #fff;
        color: var(--main-color-blue-ocean);
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 10px;
      }
    }

    .rc-dialog-close {
      color: var(--main-color-blue-ocean) !important;
      right: 10px;
      // top: 10px;

      &:hover {
        opacity: 1;
      }
    }

    .rc-dialog-body {
      transition: height 1s ease;
      background: #fff;
      height: 100%;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
      min-height: 0px;
      padding: 10px 15px;

      .btn-form {
        padding: 6px 12px;
        font-size: 13px;
        min-width: 60px;
      }
    }

    .rc-dialog-footer {
      padding: 0px;
      background: #fff;
    }
  }

  .rc-dialog-title {
    font-size: 16px;
  }

  .rc-dialog {
    height: 100%;

    .rc-dialog-content {
      height: calc(100% - 60px);
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .rc-dialog-body {
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }
    }
  }

  // .rc-dialog-content {
  //   animation: blowUpModal 0.5s cubic-bezier(0, 1.1, 0.8, 0.9) forwards;
  // }

  // &.rc-dialog-slide-fade-leave {
  //   .rc-dialog-content {
  //     animation: blowUpModalTwo 0.9s cubic-bezier(0, 1.1, 0.8, 1) forwards;
  //   }
  // }
}

.popup-crud {
  display: block;
  top: -15px;
  left: 0;
  z-index: 1;
  //.row-field {
  //  margin: 0px 5px 10px 5px;
  //}

  fieldset legend {
    font-size: 13px !important;
    font-weight: 600;
  }

  .rc-dialog-content {
    color: #333;
    background: transparent;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    .rc-dialog-header {
      border-bottom: 2px solid #c1e4ff !important;

      padding: 0px !important;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      .rc-dialog-title {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        background: #fff;
        color: var(--main-color-blue-ocean);
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 10px;
      }
    }

    .rc-dialog-close {
      color: var(--main-color-blue-ocean) !important;
      right: 10px;
      // top: 10px;

      &:hover {
        opacity: 1;
      }
    }

    .rc-dialog-body {
      transition: height 1s ease;
      background: #fff;
      //height: 100%;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
      min-height: 0px;
      padding: 10px 15px;

      .btn-form {
        padding: 6px 12px;
        font-size: 13px;
        min-width: 60px;
      }
    }

    .rc-dialog-footer {
      padding: 0px;
      background: #fff;
    }
  }

  .rc-dialog-title {
    font-size: 16px;
  }

  .rc-dialog {
    height: 100%;

    .rc-dialog-content {
      height: calc(100% - 60px);
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .rc-dialog-body {
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }
    }
  }

  // .rc-dialog-content {
  //   animation: blowUpModal 0.5s cubic-bezier(0, 1.1, 0.8, 0.9) forwards;
  // }

  // &.rc-dialog-slide-fade-leave {
  //   .rc-dialog-content {
  //     animation: blowUpModalTwo 0.9s cubic-bezier(0, 1.1, 0.8, 1) forwards;
  //   }
  // }
}

@keyframes blowUpModal {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes blowUpModalTwo {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.popup-crud {
  //     .rc-dialog-header {
  //         padding: 13px 15px;
  //         background: var(--main-background-header);

  //         .rc-dialog-title {
  //             text-transform: uppercase;
  //             color: #fff;
  //             font-size: 13px;
  //         }
  //     }

  //     .rc-dialog-content {
  //         border: 0;
  //         border-radius: 0;

  //         .rc-dialog-body {
  //             padding: 0 15px;

  //             .row-field {
  //                 display: flex;
  //                 margin: 0 0 15px 0;

  //                 >label {
  //                     margin: 0;
  //                     font-size: 13px;
  //                 }

  //                 .label-field {

  //                     margin: 0;
  //                     font-size: 13px;
  //                 }

  //                 .input-field {
  //                     flex: 1;
  //                     font-size: 13px;
  //                 }
  //             }

  //             .btn-popup {
  //                 min-width: 80px;
  //             }
  //         }

  //         .rc-dialog-footer {
  //             padding: 8px 15px;

  //             // text-align: center;
  //             .btn {
  //                 margin: 0 0 0 10px !important;
  //                 height: 32px;
  //                 font-size: 13px;
  //                 text-transform: uppercase;
  //             }
  //         }

  //         .rc-dialog-close {
  //             top: 10px;
  //             right: 10px;
  //             color: #fff;
  //             opacity: 0.8;

  //             &:hover {
  //                 opacity: 1;
  //             }
  //         }
  //     }

  //     .rc-dialog-footer {
  //         .btn {
  //             min-width: 80px;
  //         }
  //     }

  //     fieldset {
  //         padding: 0 15px;
  //         border: 1px solid #ccc;

  //         legend {
  //             font-size: 13px !important;
  //             font-weight: 600;
  //         }
  //     }

  //     .history {
  //         .ant-tabs {
  //             .ant-tabs-bar {
  //                 margin: 15px 0 8px;
  //             }

  //         }
  //     }

  .content-popup-crud {
    .icon-menu-popup {
      position: absolute;
      top: -37px;
      padding: 0px 10px;
      right: 47px;
      width: 30px;
      color: #fff;
      cursor: pointer;
      opacity: 0.8;
      height: 30px;
      background: url("../images/icon-menu-popup.png");
      background-repeat: no-repeat;
      background-size: cover;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.container-field {
  display: flex;

  .row-field {
    flex: 1;
  }

  > .btn {
    margin-left: 10px;
    height: 30px;
    font-size: 12px;
    text-transform: uppercase;
    padding: 0 10px;
  }
}

//ant
.ant-dropdown-placement-bottomCenter {
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background: #fff;
  padding: 10px 15px;
  border-radius: 5px;

  .menu-popup-list-item {
    .arrow {
      position: absolute;
      top: -12px;
      left: calc(50% - 3px);

      &::before {
        border: solid transparent;
        border-color: rgba(131, 131, 131, 0);
        border-width: 6px;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: #fff;
      }
    }

    > ul {
      width: 100%;
      list-style: none;
      margin: 0;
      padding: 10px 15px;

      .menu-popup-item {
        width: 100%;
        display: flex;
        line-height: 20px;
        cursor: pointer;
        padding: 0 8px;

        &:hover {
          background: #f5f5f5;
        }

        i {
          width: 20px;
          margin-right: 8px;
          color: #333;
          font-size: 18px;
        }

        .text-label {
          flex: 1;
          font-size: 13px;
          line-height: 20px;
          color: #333;
        }
      }
    }
  }
}

.history-popup {
  padding: 15px 0;

  .list-history {
    list-style: none;
    margin: 0;
    padding: 0;

    .item-history {
      display: flex;
      margin-bottom: 8px;
      font-size: 13px;
      line-height: 22px;
      color: #333;

      > .fa {
        font-size: 16px;
        padding-right: 5px;
      }

      .user {
        font-weight: 600;
        padding-right: 8px;
      }

      .action {
        padding-right: 8px;
      }

      .info {
        padding-right: 8px;
        font-style: italic;

        .status {
          font-weight: 600;
          padding-right: 8px;
        }
      }

      .time {
        font-style: italic;
      }
    }
  }
}

//view create- update -form
.form-info {
  fieldset {
    padding: 0 15px;
    border: 1px solid #ccc;

    legend {
      font-size: 13px !important;
      font-weight: 600;
    }
  }

  .row-field {
    display: flex;
    margin: 0 0 15px 0;

    > label {
      margin: 0;
      font-size: 13px;
      font-weight: 600;
    }

    .label-field {
      margin: 0;
      line-height: 32px;
      font-size: 13px;
      font-weight: 600;
    }

    .input-field {
      flex: 1;
      font-size: 13px;
    }
  }
}
.idtek-fieldset {
  &.fieldset-collapsed {
    padding: 0;
  }
}

.body-wrap-checkboxlist {
  > div {
    > label {
      display: flex !important;
      align-items: center !important;
    }
  }
}

.file-picker-field {
  overflow: unset !important;
}
.file-picker-field {
  .inputFile-multi {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px !important;
    .anticon-upload {
      font-size: 20px !important;
      margin-right: 5px;
    }
  }
  // .input-field{
  //   .error-field:last-child{
  //     display: none;
  //   }
  // }
}

/* width - height */
::-webkit-scrollbar {
  width: 8px; //dọc
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  opacity: 0.8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  opacity: 1;
}
