.layout-page {
  display: flex;
  overflow: hidden;
  flex: 1;
  background: rgba(222, 222, 222, 0.36);
  .header-page-v2 {
    background: #fff;
    box-shadow: 0px 3px 6px #c1e4ff;
    height: 56px;
    z-index: 1;
  }
  .header-mobile {
    .logo {
      height: 35px;
      margin-right: 15px;
    }
    .menu-header {
      width: unset !important;
      font-size: 13px;
      padding: 0px 10px;
    }
    .end-menu {
      margin-left: 10px;
      .button-header-v2 {
        width: unset !important;
        font-size: 13px;
        padding: 0px 10px;
        white-space: nowrap;
      }
      .take-money {
        margin-right: 5px;
      }
      .button-header {
        padding: 7.5px 0px;
        font-size: 13px !important;
      }
      .avatar-user {
        margin-right: 23px !important;
      }
    }
  }
}
body {
  overflow: hidden;
  font: normal 13px/20px lato, helvetica, arial, verdana, sans-serif;
  font-size: 13px;
}
* {
  font-family: lato, helvetica, arial, verdana, sans-serif;
  word-spacing: -3px;
}
.grid-report-atsx {
  * {
    word-spacing: 0px;
  }
}
