.search-cpn-phone {
    height: 28px !important;
    .wrap-search-box {
        display: flex;
        border: 1px solid #ccc;
        border-radius: 3px;

        &:hover {
            border-color: var(--main-input-border-color) !important;
            box-shadow: none !important;
        }
        &:focus-within {
            outline: unset !important;
            box-shadow: 0 0 4px var(--main-input-border-color) !important;
            border-color: var(--main-input-border-color);
        }
        .input-ej2 {
            flex: 1;
            height: 28px;
            word-spacing: normal !important;
            min-height: unset !important;
            // width: 100%;
            // border: 1px solid #ccc;
            border: 0px !important;
            border-radius: var(--main-input-border-radius);
            background-color: #fff;
            align-items: center;
            justify-content: center;
            display: flex;
            position: relative;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            padding: 10px;
            padding-left: 10px !important;

            &:hover {
                border-color: var(--main-input-border-color) !important;
                box-shadow: none !important;
            }
            &:focus-within {
                outline: unset !important;
                box-shadow: 0 !important;
                border-color: unset !important;
            }
        }
    }
    .e-control-wrapper {
        margin: 0 !important;
        border: 0 !important;
    }
    .e-input-focus {
        border: 0 !important;
    }
    .e-input-focus::before {
        background: transparent !important;
        border: 0 !important;
    }
    .e-input-focus::after {
        background: transparent !important;
        border: 0 !important;
    }
}
