$color-header: var(--color-header);

.side-menu-page {
  .wrap-button-menu {
    position: absolute;
    right: -55px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    .button-menu {
      color: $color-header;
      background: transparent;
      outline: 0 !important;
      cursor: pointer;
      font-size: 22px;
      z-index: 999;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      border: 1px solid rgb(255, 255, 255);

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
      > span {
        > svg {
          color: white;
        }
      }
    }

    // .breadcrumb {
    //   padding: 0 10px;
    //   line-height: 50px;
    //   font-size: 16px;
    //   background: transparent;
    //   color: #fff;
    //   text-transform: uppercase;
    //   margin-bottom: unset !important;
    //   position: absolute;
    //   top: 65px;
    //   left: -13px;
    //   width: 222px;
    // }
  }
}
.button-header-v2 {
  width: 134px;
  height: 34px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: #fff;
  }
  text-transform: uppercase;
}

.button-header-money {
  margin-right: 15px;
  width: 34px;
  height: 34px;
  color: #fff;
  display: flex;
  background: var(--main-color-blue-light);
  border: 1.5px solid var(--main-color-blue-light);
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: #fff;
    > svg{
      fill: var(--main-color-blue-light);
    }
  }
  text-transform: uppercase;
}
.receive-money {
  background: var(--main-color-green-light);
  border: 1.5px solid var(--main-color-green-light);
  margin-right: 16px;
  &:hover {
    color: var(--main-color-green-light);
  }
}

.take-money {
  background: #faa633;
  border: 1.5px solid #faa633;
  margin-right: 16px;
  &:hover {
    color: #faa633;
  }
}

.button-header {
  padding: 7.5px 10px;
  text-transform: uppercase;
  border-radius: 3px;
  height: 36px;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--main-color);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  white-space: nowrap;

  &:hover,
  .active {
    background: rgba(255, 255, 255, 0.2);
  }

  > i {
    font-size: 16px;
  }

  > span {
    padding-left: 8px;
  }

  > .icon-sub {
    font-size: 11px;
    margin-left: 3px;
    padding-left: 2px;
    padding-right: 2px;
  }

  &.ant-popover-open {
    > .icon-sub {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);

      &:after {
        -webkit-transition: all 150ms ease 0s;
        transition: all 150ms ease 0s;
      }
    }
  }
}

.header-submenu {
  .ant-popover-inner-content {
    padding: 0;

    .header-submenu-item {
      line-height: 21px;
      cursor: pointer;
      display: flex;

      &:hover {
        background: #f5f5f5;
        border-radius: 3px;
      }

      > i {
        line-height: 21px;
        font-size: 16px;
      }

      > span {
        line-height: 21px;
        flex: 1;
      }
    }
  }
}
