.login-page-custom {
  background: linear-gradient(95deg, #156f9f 0%, #3174eb 19%, #1499b4 100%, #3d9fb3 100%);
  height: 100vh;
  display: flex;
  flex-direction: column;

  .header-login-page {
    padding: 0 15px;
    display: flex;

    .login-logo {
      height: 80px;
      background-image: url(./images/logo-idtek.png);
      background-repeat: no-repeat;
      background-size: contain;
    }

    .phone-number {
      line-height: 80px;
      color: #fff;
      text-align: right;
      cursor: pointer;
      margin-top: 3px;

      > i {
        font-size: 20px;
        line-height: 80px;
        margin-right: 8px;
      }

      .number {
        float: right;
        font-size: 16px;
        line-height: 80px;
        color: #fff;
        text-decoration: none;
      }
    }
  }

  .div-login-page {
    display: flex;
    padding-right: 15px;
  }

  .login-background {
    display: flex;
    flex-direction: column;
    padding-right: 25px;

    .project-name {
      line-height: 36px;
      font-size: 20px;
      color: #80ffae;
      font-weight: 600;
      padding: 50px 15px 10px;
    }

    .project-description {
      line-height: 34px;
      font-size: 18px;
      color: #fff;
      padding: 10px 15px;

      .view-more {
        background: #025b80;
        border: 2px solid #025b80;
        padding: 5px 12px;
        line-height: 18px;
        font-size: 13px;
        cursor: pointer;
        color: #fff;
        text-decoration: none;
      }
    }

    .login-background-image {
      background-image: url(./images/background_login.png);
      background-position: center;
      background-size: auto 85%;
      background-repeat: no-repeat;
    }
  }

  .box-login {
    background: #fff;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    margin: auto 0;
    height: 370px;
    width: 100%;

    .pack-start {
      padding: 10px 0px 25px !important;

      .login-title {
        margin-top: 15px;
        font-size: 30px;
        color: #025b80;
        padding: 15px 20px;
        padding-left: 25px;
        font-weight: bold;
        text-transform: uppercase;
        text-align: left;
      }
    }

    .wrap-form {
      > form {
        border-width: 0px;
        padding: 0px 30px !important;
        overflow: auto;

        .textbox {
          border: none;
          border-bottom: 1px solid #ccc;
          .wrap-con-field {
            display: none !important;
          }
        }

        .body-wrap:focus-within {
          box-shadow: none !important;
          border-bottom: 1px solid #ccc;
        }


      }
    }
  }

  .footer {
    background-color: #f5f5f5;
    color: black;
    padding: 0 10px;
    text-align: center;
    line-height: 35px;
    margin-top: 11px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

.login-page-custom.login-page-desktop {
  padding: 25px 50px;
}