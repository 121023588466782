$height-button: var(--button-height);

.btn-success {
  color: #fff !important;
  background-color: var(--main-color-green-light) !important;
  border-color: var(--main-color-green-light) !important;
  border-radius: 5%;
  cursor: pointer;
  padding: 0 8px !important;
  line-height: 28px !important;
  height: $height-button !important;
  text-transform: uppercase;
  font-size: 13px !important;

  &:hover,
  &:focus {
    color: #fff !important;
    background-color: var(--main-color-green) !important;
  }

  &:focus {
    outline: unset !important;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5) !important;
  }
}

.btn-transfer-wf {
  color: #fff !important;
  background-color: rgb(0, 123, 255) !important;
  border-color: rgb(0, 123, 255) !important;
  border-radius: 5%;
  cursor: pointer;
  padding: 0 8px !important;
  line-height: 28px !important;
  height: $height-button !important;
  text-transform: uppercase;
  font-size: 13px !important;

  &:hover,
  &:focus {
    color: #fff !important;
    background-color: rgb(0, 123, 255) !important;
  }

  &:focus {
    outline: unset !important;
    box-shadow: rgb(0, 123, 255) !important;
  }
}

.btn-word {
  color: #fff !important;
  background-color: #2980b9 !important;
  border-color: #2980b9 !important;
  border-radius: 5%;
  cursor: pointer;
  padding: 0 8px !important;
  line-height: 28px !important;
  height: $height-button !important;
  text-transform: uppercase;
  font-size: 13px !important;

  &:hover,
  &:focus {
    color: #fff !important;
    background-color: #2980b9 !important;
  }

  &:focus {
    outline: unset !important;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5) !important;
  }
}

.btn-excel {
  color: #fff !important;
  background-color: #27ae60 !important;
  border-color: #27ae60 !important;
  border-radius: 5%;
  cursor: pointer;
  padding: 0 8px !important;
  line-height: 28px !important;
  height: $height-button !important;
  text-transform: uppercase;
  font-size: 13px !important;

  &:hover,
  &:focus {
    color: #fff !important;
    background-color: #27ae60 !important;
  }

  &:focus {
    outline: unset !important;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5) !important;
  }
}

.btn-success-check-in {
  color: #fff !important;
  background-color: var(--main-color-green-light) !important;
  border-color: var(--main-color-green-light) !important;
  border-radius: 5%;
  cursor: pointer;
  padding: 0 8px !important;
  line-height: 28px !important;
  height: 60px !important;
  text-transform: uppercase;
  font-size: 13px !important;

  &:hover,
  &:focus {
    color: #fff !important;
    background-color: var(--main-color-green) !important;
  }

  &:focus {
    outline: unset !important;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5) !important;
  }
}

.btn-secondary {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
  border-radius: 5%;
  cursor: pointer;
  padding: 0 8px !important;
  line-height: 28px !important;
  height: $height-button !important;
  text-transform: uppercase;
  font-size: 13px !important;

  &:hover,
  &:focus {
    color: #fff !important;
    background-color: #545c63 !important;
  }

  &:focus {
    outline: unset !important;
    box-shadow: #6c757d !important;
  }
}

.btn-clone {
  color: #fff !important;
  background-color: #7a7d29 !important;
  border-color: #7a7d29 !important;
  border-radius: 5%;
  cursor: pointer;
  padding: 0 8px !important;
  line-height: 28px !important;
  height: $height-button !important;
  text-transform: uppercase;
  font-size: 13px !important;

  &:hover,
  &:focus {
    color: #fff !important;
    background-color: #5b5e20 !important;
  }

  &:focus {
    outline: unset !important;
    box-shadow: #4f521c !important;
  }
}

.btn-danger {
  color: #fff !important;
  background-color: var(--main-color-red) !important;
  border: none;
  border-radius: 5%;
  cursor: pointer;
  padding: 0 8px !important;
  line-height: 28px !important;
  height: $height-button !important;
  text-transform: uppercase;
  font-size: 13px !important;

  &:hover {
    color: #fff !important;
    background-color: #c82333 !important;
  }

  &:focus {
    outline: unset !important;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5) !important;
  }
}

.btn-danger-check-in {
  color: #fff !important;
  background-color: var(--main-color-red) !important;
  border: none;
  border-radius: 5%;
  cursor: pointer;
  padding: 0 8px !important;
  line-height: 30px !important;
  height: 60px !important;
  text-transform: uppercase;
  font-size: 13px !important;

  &:hover {
    color: #fff !important;
    background-color: #c82333 !important;
  }

  &:focus {
    outline: unset !important;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5) !important;
  }
}

.btn-setting {
  color: #fff !important;
  background-color: #ff7043 !important;
  border: none;
  border-radius: 5%;
  cursor: pointer;
  width: 40px !important;
  line-height: 28px !important;

  &:hover {
    color: #fff !important;
    background-color: #ff7043 !important;
  }

  &:focus {
    outline: unset !important;
    box-shadow: #a34d2c !important;
  }
  > span {
    margin-left: 7px;
  }
}

.btn-primary {
  color: #fff !important;
  background-color: var(--main-color-blue-ocean) !important;
  border: none;
  border-radius: 5%;
  cursor: pointer;
  padding: 0 8px !important;
  line-height: 28px !important;
  height: $height-button !important;
  text-transform: uppercase;
  font-size: 13px !important;

  &:hover {
    color: #fff !important;
    background-color: var(--main-color) !important;
  }

  &:focus {
    outline: unset !important;
    box-shadow: var(--main-color) !important;
  }
}
.btn-primary-lighter {
  color: #fff !important;
  background-color: var(--main-color-blue-light) !important;
  border: none;
  border-radius: 5%;
  cursor: pointer;
  padding: 0 8px !important;
  line-height: 28px !important;
  height: $height-button !important;
  text-transform: uppercase;
  font-size: 13px !important;

  &:hover {
    color: #fff !important;
    background-color: #2d9ada !important;
  }

  &:focus {
    outline: unset !important;
    box-shadow: #2d9ada !important;
  }
}
.btn-add-partner {
  margin-left: 5px;
  color: #fff !important;
  background-color: #007bff !important;
  border: none;
  border-radius: 10%;
  cursor: pointer;
  max-height: 30px;
  display: flex;
  align-items: center;

  &:hover {
    color: #fff !important;
    background-color: #007bff !important;
  }

  &:focus {
    outline: unset !important;
    box-shadow: #2c459c !important;
  }
}

.btn-review-file-pid {
  margin-left: 5px;
  color: #fff !important;
  background-color: #e3791c !important;
  border: none;
  border-radius: 10%;
  cursor: pointer;
  height: 20px;
  display: flex;
  align-items: center;

  &:hover {
    color: #fff !important;
    background-color: #e3791c !important;
  }

  &:focus {
    outline: unset !important;
    box-shadow: #e3791c !important;
  }
}

.btn-review-risk {
  margin-left: 5px;
  color: #fff !important;
  background-color: #ff5722 !important;
  border: none;
  border-radius: 10%;
  cursor: pointer;
  max-height: 30px;
  display: flex;
  align-items: center;

  &:hover {
    color: #fff !important;
    background-color: #ff7d54 !important;
  }

  &:focus {
    outline: unset !important;
    box-shadow: #ff7d54 !important;
  }
}

.btn-review-file-pdf {
  margin-left: 5px;
  color: #fff !important;
  background-color: #ff1d34 !important;
  border: none;
  border-radius: 10%;
  cursor: pointer;
  max-height: 30px;
  display: flex;
  align-items: center;

  &:hover {
    color: #fff !important;
    background-color: #ff1d34 !important;
  }

  &:focus {
    outline: unset !important;
    box-shadow: #ff1d34 !important;
  }
}

.btn-basic {
  min-width: 50px;
  border-color: #ccc !important;
  display: flex !important;
  align-items: center !important;
  color: inherit !important;
  padding: 0 8px !important;
  line-height: 28px !important;
  height: $height-button !important;
  text-transform: uppercase;
  font-size: 13px !important;

  > i {
    margin-top: 1px;
  }

  &:hover {
    border-color: rgb(0, 123, 255) !important;
    background: #fff !important;
    color: rgb(0, 123, 255) !important;
  }
}

.btn-plus {
  text-align: center;
  font-weight: 600 !important;
  justify-content: center;
}

.btn-form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  cursor: pointer;
  padding: 0 8px !important;
  line-height: 28px !important;
  font-size: 13px !important;
}

.btn-import-excel {
  height: $height-button !important;
  display: flex !important;
  align-items: center !important;
  background-color: #46b8da !important;
  border-color: #46b8da !important;
  color: #fff !important;
  box-shadow: none !important;
  transition: unset !important;

  &:hover {
    border-color: #46b8da !important;
    background: #fff !important;

    i,
    span {
      color: #007bff !important;
    }
  }
}

.ant-tabs {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 13px;

  .ant-tabs-tab {
    font-size: 13px;
    user-select: none;
    text-transform: uppercase;
  }

  .ant-tabs-top-content {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    .ant-tabs-tabpane {
      width: 100%;
      //position: relative;
      //height: calc(100% - 40px) !important;
      height: 100% !important;
      //position: absolute;
      top: 40px;
      overflow: auto;
    }
  }

  .ant-tabs-tab-active.ant-tabs-tab {
    height: 40px;
    border: 0 !important;
    border-bottom: 2px solid #007bff !important;
    color: #555;
    font-weight: 700 !important;

    &:focus {
      outline: unset !important;
      box-shadow: #ffffff !important;
    }
  }
}

.btn-transfer {
  height: $height-button;
  font-size: 13px !important;
  font-weight: 400;
  margin-left: 10px;
  background-color: var(--main-color-blue-ocean) !important;
  color: #fff !important;

  &:hover {
    background-color: #026397 !important;
    color: #fff !important;
    border-color: transparent;
  }
}

.btn-import-global {
  height: $height-button;
  font-size: 12px !important;
  font-weight: 400 !important;
  margin-left: 10px;
  background-color: #00cec9 !important;
  color: #fff !important;
  width: 90px;

  &:hover {
    background-color: #7ef7f5 !important;
    color: #fff !important;
    border-color: transparent;
  }
}

.btn-success-global {
  height: $height-button !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  margin-left: 10px;
  background-color: #2ece89 !important;
  color: #fff !important;
  width: 120px;

  &:hover {
    background-color: #80d1ae !important;
    color: #fff !important;
    border-color: transparent;
  }
}

.btn-warning-global {
  height: $height-button !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  margin-left: 10px;
  background-color: #f0ad4e !important;
  color: #fff !important;
  width: 120px !important;

  &:hover {
    background-color: #f0ad4e !important;
    color: #fff !important;
    border-color: transparent;
  }
}

.btn-danger-global {
  height: $height-button !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  margin-left: 10px;
  background-color: #d43f3a !important;
  color: #fff !important;
  width: 120px !important;

  &:hover {
    background-color: #d43f3a !important;
    color: #fff !important;
    border-color: transparent;
  }
}

.btn-request-status-global {
  height: $height-button;
  font-size: 12px;
  font-weight: 400;
  margin-left: 5px;
  background-color: #00cec9;
  color: #fff;

  // width: 40px;
  &:hover {
    background-color: #7ed6df;
    color: #000000;
    border-color: transparent;
  }
}

.btn-back-global {
  height: $height-button;
  font-size: 12px;
  font-weight: 400;
  margin-left: 5px;
  background-color: #ccc;
  color: #555;

  // width: 40px;
  &:hover {
    background-color: #ddd;
    color: #555;
    border-color: transparent;
  }
}

.btn-reject-global {
  height: $height-button;
  font-size: 12px;
  font-weight: 400;
  margin-left: 10px;
  background-color: #c62828;
  color: #fff;
  width: 120px;

  &:hover {
    background-color: #e53935;
  }
}

.btn-download-global {
  height: $height-button !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  margin-left: 10px !important;
  background-color: #959b3e !important;
  color: #fff !important;
  width: 120px;

  &:hover {
    background-color: #d8e247;
  }
}

.btn-info-global {
  height: $height-button;
  font-size: 12px !important;
  font-weight: 400 !important;
  margin-left: 10px !important;
  background-color: #17a2b8 !important;
  color: #fff !important;
  width: 120px;

  &:hover {
    color: #fff !important;
    background-color: #43becc !important;
  }
}
.action-row-overlay-menu-item {
  display: flex;
  padding: 11px 10px !important;
  border: 1px solid #ffffff;
  line-height: 20px;
  display: flex;
  justify-content: flex-start;
  &:hover {
    border: 1px solid #c6f5ff;
  }
  .action-row-overlay-menu-item-icon {
    margin-top: -3px;
    margin-left: 7px;
    .anticon {
      border: none !important;
    }
    .anticon-edit {
      color: #2dce89;
    }
    .anticon-info-circle {
      color: #5e72e4;
    }
    .anticon-delete {
      color: var(--main-color-red);
    }
  }
}
